<template>
  <div class="main-moradias fade-in-up">
    <b-jumbotron class="title-page mb-5 mt-5 title_page">
      <h1 class="text-center font-weight-bold">Moradias</h1>
    </b-jumbotron>
    <bread-cpv class="adjustment-breadcrumb"></bread-cpv>

    <b-jumbotron fluid class="instruction_for_filters">
      <div class="header">
        <span> Selecione as opções de localidade e moradia para encontrar a melhor opção para você. </span>
      </div>
    </b-jumbotron>

    <b-jumbotron fluid class="bg-search">
      <!-- Filtro Select -->
      <div class="d-block ml-auto mr-auto">
        <b-row no-gutters>
          <b-col cols="6">
            <b-nav class="d-flex justify-content-center">
              <b-nav-item
                v-for="(unidade, index) in unidades"
                :key="index"
                v-model="filter"
                :value="unidade.type"
                :active="activeUnidade === unidade.type"
                @click="selectUnidade(unidade.type)"
                class="btn-select"
              >
                <span>{{ unidade.type }}</span>
              </b-nav-item>
            </b-nav>
          </b-col>
          <b-col cols="6">
            <b-nav class="d-flex justify-content-center">
              <b-nav-item
                v-for="(moradia, index) in moradias"
                :key="index"
                v-model="filter"
                :value="moradia.type"
                :active="activeMoradia === moradia.type"
                @click="selectMoradia(moradia.type)"
                class="btn-select"
              >
                <span>{{ moradia.type }}</span>
              </b-nav-item>
            </b-nav>
          </b-col>
        </b-row>
      </div>
    </b-jumbotron>

    <div v-if="search === ''">
      <b-container>
        <b-row>
          <b-col xs="12" md="6" v-for="(moradias, index) in filteredItems" :key="index" no-gutters>
            <b-card no-body class="card-moradias">
              <b-card-header class="header-card">
                <span>{{ moradias.title }}</span>
              </b-card-header>
              <b-card-body class="body-card">
                <div class="information">
                  <b-list-group class="group-list">
                    <b-list-group-item
                      v-for="(informations, index) in moradias.informationList"
                      :key="index"
                      v-show="informations.list_item !== ''"
                      class="group-list-item"
                    >
                      <span>{{ informations.list_item }}</span>
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div v-show="moradias.informations !== ''" class="information">
                  <div v-html="moradias.information"></div>
                </div>
                <div v-show="moradias.address !== ''" class="address">
                  <span class="title-information">Endereço: </span>
                  <span v-html="moradias.address"></span>
                </div>
                <div v-show="moradias.phone !== ''" class="telephone">
                  <span class="title-information">Telefone: </span>
                  <span v-html="moradias.phone"></span>
                </div>
                <div v-show="moradias.responsible !== ''" class="responsible">
                  <span class="title-information">Responsável: </span>
                  <span>{{ moradias.responsible }}</span>
                </div>
                <div v-show="moradias.email !== ''" class="email">
                  <span class="title-information">E-mail: </span>
                  <a v-bind:href="'mailto:' + moradias.email">{{ moradias.email }}</a>
                </div>
                <div v-show="moradias.nameUrl !== ''" class="url">
                  <span class="title-information">Site: </span>
                  <a v-bind:href="[`${moradias.url}`]" v-show="moradias.nameUrl">{{ moradias.nameUrl }}</a>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container>
        <b-row v-if="search !== ''">
          <b-col xs="12" md="6" v-for="(moradias, index) in filteredItems" :key="index">
            <b-card no-body class="card-moradias">
              <template #header>
                {{ moradias.title }}
              </template>
              <b-card-body>
                <div v-show="moradias.information.list_item !== ''" class="information">
                  <b-list-group>
                    <b-list-group-item>
                      <span>{{ moradias.information }}</span>
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div v-show="moradias.address !== ''" class="address">
                  <span>{{ moradias.address }}</span>
                </div>
                <div v-show="moradias.phone !== ''" class="telephone">
                  <span>{{ moradias.phone }}</span>
                </div>
                <div v-show="moradias.email !== ''" class="email">
                  <span>{{ moradias.email }}</span>
                </div>
                <div v-show="moradias.url !== ''" class="url">
                  <span>{{ moradias.url }}</span>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
// import Vue from "vue";
// import { BJumbotron } from "bootstrap-vue";
// import { BCard, BCardBody } from "bootstrap-vue";
// import { BListGroup, BListGroupItem } from "bootstrap-vue";
// import { BBreadcrumb, BBreadcrumbItem } from "bootstrap-vue";
// Vue.use(BListGroup)

import BreadCpv from '@/components/global/bread-cpv.vue';

export default {
  name: "moradiaTeste",
  metaInfo: { title: 'Moradias' },
  components: { BreadCpv },
  created() {
    this.filteredItems = this.show_moradias.data;
  },
  computed: {
    filter_titulo() {
      return this.show_moradias.data.filter((retDao) => {
        return retDao.titulo.toLowerCase().match(this.filter.toLowerCase());
      }, (this.search = []));
    },
  },
  data() {
    return {
      id: "",
      search: "",
      filter: [],
      show_moradias: {
        data: [
          {
            id: 0,
            title: "Pensionato para Rapazes",
            informationList: [
              {
                id: 0,
                list_item: "- Pensão somente para rapazes.",
              },
              {
                id: 1,
                list_item: "- Café da manhã, almoço e jantar.",
              },
              { id: 2, list_item: "- Lavanderia e Wi-fi." },
              { id: 3, list_item: "- R$ 2.800,00/mês" },
            ],
            address: "Rua Alagoas, Nº 336 - Higienópolis, São Paulo - SP",
            phone: "(11) 9 9939-2214",
            responsible: "Regina",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Consolação",
            filterMoradia: "Pensionato",
          },
          {
            id: 1,
            title: "Apartamentos Mobiliados Residencial Studio",
            informationList: [],
            information: "Alugam-se apartamentos no Residencial Studio. <br> Tratar com Lourdes Prate.",
            address: "Estão a um quarteirão do CPV - Consolação",
            phone: "(11) 97161-2469 (Whatsapp)",
            responsible: "",
            email: "lourdesimobiliaria@gmail.com",
            nameUrl: "",
            url: "",
            filter: "Consolação",
            filterMoradia: "Studio",
          },
          {
            id: 2,
            title: "F.L Resindece",
            informationList: [],
            information: "",
            address: "Rua Alagoas, Nº 336 - Higienópolis, São Paulo - SP",
            phone: "(11) 3246-5450",
            responsible: "",
            email: "",
            url: "http://flresidence.com.br",
            nameUrl: "F.L Residence",
            filter: "Vila Olímpia",
            filterMoradia: "Flat",
          },

          {
            id: 3,
            title: "Olympia Residence",
            informationList: [],
            information: "",
            address: "Rua Gomes de Carvalho, 850 - Vila Olímpia",
            phone: "",
            responsible: "",
            email: "reservas@olympiaresidence.com.br",
            nameUrl: "Olympia Residence",
            url: "https://olympia-residence.allsaopaulohotels.com/pt/",
            filter: "Vila Olímpia",
            filterMoradia: "Flat",
          },
          {
            id: 4,
            title: "JSI - Jardim Sul",
            informationList: [],
            information: "",
            address: "Rua Nelson Gama de Oliveira, 120 - Vila Andrade",
            phone: "(11) 3773-3303",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 5,
            title: "Century 21 Gold",
            informationList: [],
            information: "",
            address: "Rua Doutor Luiz Migliano, 340 - Jd. Vazani",
            phone: "(11) 3742-1533",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 6,
            title: "Fernandes Mera",
            informationList: [],
            information: "",
            address: "Av. Dr. Guilherme Dumont Vilares, 2476 - Jd. Londrina",
            phone: "(11) 3058-8787",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 7,
            title: "Sucefi Imóveis",
            informationList: [],
            information: "",
            address: "Rua Sebastião Francisco, 3480 - Vila Andrade",
            phone: "(11) 3742-1533",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 8,
            title: "Plus Imóveis",
            informationList: [],
            information: "",
            address: "Rua Deputado João Sussumu Hirata, 539 - Vila Andrade",
            phone: "(11) 3022-6000",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 9,
            title: "Coelho da Fonseca",
            informationList: [],
            information: "",
            address: "Av. Giovanni Gronchi, 4020 - Vila Andrade",
            phone: "(11) 3745-6000",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 10,
            title: "Lincoln Yuji Imóveis",
            informationList: [],
            information: "",
            address: "Av. Giovanni Gronchi, 6195 (Sala 1412) - Vila Andrade",
            phone: "(11) 96013-7288",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 11,
            title: "Olimpo Imobiliaria",
            informationList: [],
            information: "",
            address: "Rua Nazira Carone, 9 - Jd. Ampliação",
            phone: "(11) 3739-0333",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 12,
            title: "Imobiliária Objetivo",
            informationList: [],
            information: "",
            address: "Av. Dr. Guilherme Dumont Vilares, 566 - Jd. Londrina",
            phone: "(11) 3507-1000",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 13,
            title: "Imóveis Bamberg",
            informationList: [],
            information: "",
            address: "Rua R. Mal. Hastimphilo de Moura, 233 (Loja 04) - Morumbi",
            phone: "(11) 37400-5555",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 14,
            title: "Aimbi Imóveis",
            informationList: [],
            information: "",
            address: "Rua José Ramon Urtiza, 890 - Vila Andrade ",
            phone: "(11) 3507-3836",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 15,
            title: "Imobiliária Morumbi Sul",
            informationList: [],
            information: "",
            address: "Av. Dr. Guilherme Dumont Vilares, 560 (1º Andar) - Jd. Vazani ",
            phone: "(11) 2539-3301",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 16,
            title: "Minha Casa Imobiliária",
            informationList: [],
            information: "",
            address: "Av. Dr. Guilherme Dumont Vilares, 732 - Morumbi",
            phone: "(11) 3739-2528",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 17,
            title: "m² Consultoria Imobiliária",
            informationList: [],
            information: "",
            address: "Rua Adalívia de Tolêdo, 177 - Real Parque",
            phone: "(11) 3507-3836",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 18,
            title: "Imobiliária Morumbi Haus",
            informationList: [],
            information: "",
            address: "Rua Nelson Gama de Oliveira, 311 (Conjunto 111) - Morumbi",
            phone: "(11) 2592-9800",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 19,
            title: "Amel Imóveis",
            informationList: [],
            information: "",
            address: "Rua Doutor Luiz Migliano, 1110 (Conjunto 111) - Jd. Taboão",
            phone: "(11) 3507-0707",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 19,
            title: "Lavieri Imóveis",
            informationList: [],
            information: "",
            address: "Rua Ministro Heitor Bastos Tigre, 81 - Jd. Monte Kemel",
            phone: "(11) 3740-4050",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 20,
            title: "Fanel Imóveis",
            informationList: [],
            information: "",
            address: "Rua Regente León Kaniefsky, 113 - Vila Progredior",
            phone: "(11) 3744-5000",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 21,
            title: "Guedala Imóveis",
            informationList: [],
            information: "",
            address: "Rua Dom Armando Lombardi, 462 - Vila Progredior",
            phone: "(11) 3723-4050",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 22,
            title: "Laerdi Imóveis",
            informationList: [],
            information: "",
            address: "Av. Giovanni Gronchi, 2287 - Morumbi",
            phone: "(11) 3740-4000",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 23,
            title: "Imobiliária Morumbi Mix",
            informationList: [],
            information: "",
            address: "Rua Dr. Luiz Migliano, 928 - Portal do Morumbi",
            phone: "(11) 3805-1111",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 24,
            title: "Plus Imóveis",
            informationList: [],
            information: "",
            address: "Rua Regente León Kaniefsky, 512 - Vila Progedior",
            phone: "(11) 5055-9900",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 25,
            title: "Imóveis Morumbi",
            informationList: [],
            information: "",
            address: "Av. Morumbi, 6045 - Morumbi",
            phone: "(11) 3755-9999",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Morumbi",
            filterMoradia: "Imobiliária",
          },
          {
            id: 26,
            title: "Ricofer Imóveis",
            informationList: [],
            information: "",
            address: "Rua Ribeirão Claro, 572 – Vila Olímpia",
            phone: "(11) 2157-5165 / (11) 98544-6910",
            responsible: "",
            email: "ricoferimoveis@gmail.com",
            nameUrl: "Ricofer Imóveis",
            url: "https://www.ricoferimoveis.com.br/",
            filter: "Vila Olímpia",
            filterMoradia: "Imobiliária",
          },
          {
            id: 26,
            title: "C.A. Imóveis",
            informationList: [],
            information: "",
            address: "Rua Fausto Delduque, 108 - Campo Belo",
            phone: "(11) 3845-3255 / (11) 97157-6157",
            responsible: "",
            email: "luiz@caimoveis.com",
            nameUrl: "C.A Imóveis",
            url: "https://caimoveis.com/",
            filter: "Vila Olímpia",
            filterMoradia: "Imobiliária",
          },
          {
            id: 27,
            title: "Double Tree by Hilton",
            informationList: [],
            information: "",
            address: "R. Manuel Guedes, 320 – Itaim Bibi",
            phone: "(11) 3066-2699",
            responsible: "",
            email: "vendas.dtit@atlanticahotels.com.br",
            nameUrl: "Double Tree By Hilton",
            url: "https://www.hiltonhotels.com/pt_BR/brasil/doubletree-by-hilton-sao-paulo-itaim/",
            filter: "Vila Olímpia",
            filterMoradia: "Hotel",
          },
          {
            id: 28,
            title: "Quality Suítes Long Stay",
            informationList: [],
            information: "",
            address: "R. Alvorada, 1009 - Itaim Bibi",
            phone: "(11) 3848-4242",
            responsible: "",
            email: "reservas.qsvo@atlanticahotels.com.br",
            nameUrl: "Quality Suítes Long Stay",
            url: "https://www.reserveatlantica.com.br/hotel/quality-suites-long-stay-vila-olimpia",
            filter: "Vila Olímpia",
            filterMoradia: "Hotel",
          },
          {
            id: 29,
            title: "Pullman & Grand Mercury",
            informationList: [],
            information: "",
            address: "Rua Olimpíadas, 205 – Vila Olímpia",
            phone: "(11) 3049-6622 / 2161-4900",
            responsible: "",
            email: "h8938-re@acor.com.br",
            nameUrl: "Pullman & Grand Mercury ",
            url: "https://all.accor.com/hotel/8942/index.pt-br.shtml",
            filter: "Vila Olímpia",
            filterMoradia: "Hotel",
          },
          {
            id: 30,
            title: "Estanplaza ",
            informationList: [],
            information: "",
            address: "<br>Av. Eng. Luis Carlos Berrini, 853 – Itaim Bibi <br> Rua Funchal, 281 – Vila Funchal",
            phone: "(11) 5509-8900 (Berrini) / (11) 3059-3277 (Funchal)",
            responsible: "",
            email: "reservas@estanplaza.com.br / funchal@estanplaza.com.br",
            nameUrl: "Estanplaza",
            url: "https://estanplaza.com.br/",
            filter: "Vila Olímpia",
            filterMoradia: "Hotel",
          },
          {
            id: 31,
            title: "Gran Estanconfor",
            informationList: [],
            information: "",
            address: "Rua João Cachoeira, 292 ( Esquina com a Rua Jesuíno Arruda) – Itaim Bibi",
            phone: "(11) 3704-6000",
            responsible: "",
            email: "itaim@estanconfor.com.br",
            nameUrl: "Gran Estanconfor",
            url: "https://estanconfor.com.br/hoteis/gran-estanconfor-itaim",
            filter: "Vila Olímpia",
            filterMoradia: "Hotel",
          },
          {
            id: 32,
            title: "Confort",
            informationList: [],
            information: "",
            address: "Av. Sabiá, 825 – Moema",
            phone: "(11) 2197-7200",
            responsible: "",
            email: "reservas.coib@atlanticahotels.com.br",
            nameUrl: "Gran Estanconfor",
            url: "https://www.reserveatlantica.com.br/",
            filter: "Vila Olímpia",
            filterMoradia: "Hotel",
          },
          {
            id: 33,
            title: "Blue Tree Premium",
            informationList: [],
            information: "",
            address: "Av. Faria Lima, 3989 – Vila Olímpia",
            phone: "(11) 3896-7544 / (11) 3896-7545",
            responsible: "",
            email: "reservas1.farialima@bluetree.com.br",
            nameUrl: "Gran Estanconfor",
            url: "https://www.bluetree.com.br/",
            filter: "Vila Olímpia",
            filterMoradia: "Hotel",
          },
          {
            id: 34,
            title: "The World Hotels",
            informationList: [],
            information: "",
            address: "Rua Gomes de Carvalho, 1005 – Vila Olímpia",
            phone: "(11) 3049-6700",
            responsible: "",
            email: "",
            nameUrl: "The World Hotels",
            url: "https://theworld.com.br/vilaolimpia/",
            filter: "Vila Olímpia",
            filterMoradia: "Hotel",
          },
          {
            id: 35,
            title: "Meliã",
            informationList: [],
            information: "",
            address: "Av. Ibirapuera, 2534 – Moema",
            phone: "(11) 3509-8701",
            responsible: "",
            email: "reservas2.ibirapuera@meliahotels.com.br",
            nameUrl: "Meliã ",
            url: "https://www.melia.com/pt",
            filter: "Vila Olímpia",
            filterMoradia: "Hotel",
          },
          {
            id: 36,
            title: "Mercure JK",
            informationList: [],
            information: "",
            address: "Av. Funchal, 111 – Vila Funchal",
            phone: "(11) 3046-3800 / (11) 3046 3900",
            responsible: "",
            email: "h2128-re@accor.com.br",
            nameUrl: "Mercure JK",
            url: "https://all.accor.com/hotel/2128/index.pt-br.shtml",
            filter: "Vila Olímpia",
            filterMoradia: "Hotel",
          },
          {
            id: 37,
            title: "Mercure",
            informationList: [],
            information: "",
            address: "Rua Santa Justina, 210 – Vila Olímpia",
            phone: "(11) 3089-6222",
            responsible: "",
            email: "h3206-re@accor.com.br",
            nameUrl: "Mercure Vila Olímpa",
            url: "https://all.accor.com/hotel/3206/index.pt-br.shtml",
            filter: "Vila Olímpia",
            filterMoradia: "Hotel",
          },
          {
            id: 38,
            title: "Hotels Radisson",
            informationList: [],
            information: "",
            address: "Rua Fidêncio Ramos, 420 – Itaim Bibi",
            phone: "(11) 4395-3279",
            responsible: "",
            email: "reservas.rvo@atlanticahotels.com.br",
            nameUrl: "Radisson Vila Olímpia",
            url: "https://www.reserveatlantica.com.br/hotel/radisson-vila-olimpia-sao-paulo",
            filter: "Vila Olímpia",
            filterMoradia: "Hotel",
          },
          {
            id: 39,
            title: "Flat Prive Cidade Jardim",
            informationList: [],
            information: "",
            address: "Rua Ponte Delgada, 76 – Vila Olímpia",
            phone: "(11) 3849-3211 / (11) 3849-5584",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Vila Olímpia",
            filterMoradia: "Flat",
          },
          {
            id: 40,
            title: "Pensão Maria Imaculada (F)",
            informationList: [
              {
                id: 0,
                list_item: "- Pensão para Mulheres.",
              },
              {
                id: 1,
                list_item: "- Quartos individuais, 2 ou 3 pessoas.",
              },
              {
                id: 2,
                list_item: "- Com café da manhã e almoço.",
              },
              {
                id: 3,
                list_item: "- Valores na entrevista das 10h00 às 16h00.",
              },
            ],
            information: "",
            address: "Al. Itu, 920 – Cerqueira Cesar",
            phone: "(11) 3061-2877",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Consolação",
            filterMoradia: "Pensionato",
          },
          {
            id: 41,
            title: 'Moradia "Viver Bem"',
            informationList: [
              {
                id: 0,
                list_item: "- Pensão para Homens e Mulheres estudantes.",
              },
              {
                id: 1,
                list_item: "- Quartos individuais ou duplos com mobílias inclusas.",
              },
              {
                id: 2,
                list_item: "- Internet com Wi-fi.",
              },
              {
                id: 3,
                list_item: "- Cozinha equipadas e com armários privativos.",
              },
              {
                id: 4,
                list_item: "- Seleção e Segurança Privilegiada.",
              },
              {
                id: 5,
                list_item: "- Preços a partir de R$1.250,00",
              },
            ],
            information: "",
            address: "Al. Itu, 920 – Cerqueira Cesar",
            phone: "(11) 3061-2877",
            responsible: "Sergio",
            email: "sergiodonato@bol.com.br",
            nameUrl: "Moradia Viver Bem",
            url: "https://moradiasviverbem.com.br/",
            filter: "Consolação",
            filterMoradia: "Pensionato",
          },
          {
            id: 42,
            title: "Moradia do Estudante",
            informationList: [
              {
                id: 0,
                list_item: "- Pensão para Homens.",
              },
              {
                id: 1,
                list_item: "- Suítes com banheiros privativos.",
              },
              {
                id: 2,
                list_item: "- Áreas comuns com copa, cozinha, lavanderia e sala de estudos.",
              },
              {
                id: 3,
                list_item: "- Suítes mobíliadas.",
              },
              {
                id: 4,
                list_item: "- Suítes a partir de R$ 1.100,00 a R$ 1.250,00",
              },
            ],
            information: "",
            address: "Rua Teixeira Mendes, 87 – Liberdade",
            phone: "(11) 3277-3061 / (11) 99707-2181 c/ Gleci",
            responsible: "",
            email: "pensionatoemsaopaulo@hotmail.com",
            nameUrl: "",
            url: "",
            filter: "Consolação",
            filterMoradia: "Pensionato",
          },
          {
            id: 43,
            title: "Quality Suítes Long Stay",
            informationList: [],
            information: "",
            address: "Rua Bela Cintra, 521 – Consolação",
            phone: "(11) 4003-4658",
            responsible: "",
            email: "reservas.qsvo@atlanticahotels.com.br",
            nameUrl: "Quality Suítes Long Stay",
            url: "https://www.reserveatlantica.com.br/hotel/quality-suites-long-stay-vila-olimpia",
            filter: "Consolação",
            filterMoradia: "Hotel",
          },
          {
            id: 44,
            title: "Hotel San Gabriel",
            informationList: [],
            information: "",
            address: "Rua Frei Caneca, 1006 – Consolação",
            phone: "(11) 3253-2279",
            responsible: "",
            email: " reserva@sangabriel.com.br ",
            nameUrl: "Hotel San Gabriel",
            url: "https://www.sangabriel.com.br/",
            filter: "Consolação",
            filterMoradia: "Hotel",
          },
          {
            id: 45,
            title: "Land Mark Hotel",
            informationList: [
              {
                id: 0,
                list_item: "- 1 vaga na garagem.",
              },
              {
                id: 1,
                list_item: "- TV a cabo, água, luz, telefone e condomínio.",
              },
              {
                id: 2,
                list_item: "- Sala de ginástica.",
              },
              {
                id: 3,
                list_item: "- Piscina.",
              },
              {
                id: 4,
                list_item: "- Restaurante à parte.",
              },
              {
                id: 5,
                list_item: "- Aceitam negociar.",
              },
            ],
            information: "",
            address: "Al. Jaú, 1607 – Cerqueira Cesar",
            phone: "(11) 3087-8600 / (11) 99149-1016 / (11) 94320-1782 (Whatsapp)",
            responsible: "",
            email: "reservas@landmarkhotel.com.br",
            nameUrl: "Land Mark Hotel",
            url: "https://www.landmarkhotel.com.br/",
            filter: "Consolação",
            filterMoradia: "Hotel",
          },
          {
            id: 46,
            title: "Riema Mont Hermont Flat",
            informationList: [
              {
                id: 0,
                list_item: "- TV a cabo, água, luz, telefone e condomínio.",
              },
              {
                id: 1,
                list_item: "- Sala de ginástica.",
              },
              {
                id: 2,
                list_item: "- Piscina.",
              },
            ],
            information: "",
            address: "Rua Fernando de Albuquerque, 86 – Consolação",
            phone: "(11) 3256-8311",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Consolação",
            filterMoradia: "Flat",
          },
          {
            id: 47,
            title: "H4 Opera Jardins",
            informationList: [],
            information: "",
            address: "Al. Lorena, 1756 – Cerqueira Cesar",
            phone: "(11) 3256-8311",
            responsible: "",
            email: "",
            nameUrl: "",
            url: "",
            filter: "Consolação",
            filterMoradia: "Hotel",
          },
          {
            id: 47,
            title: "Massis Stars",
            informationList: [],
            information: "",
            address: "Rua Luis Coelho, 80 – Consolação",
            phone: "(11) 3141-4400",
            responsible: "Charles",
            email: "",
            nameUrl: "Massis Stars",
            url: "https://www.hotelmassis.com.br/",
            filter: "Consolação",
            filterMoradia: "Hotel",
          },
          {
            id: 48,
            title: "Elysee Plaza Flat Service",
            informationList: [
              {
                id: 0,
                list_item: "- Kitnets mobíliadas.",
              },
              {
                id: 1,
                list_item: "Saiba mais sobre os preços e serviços oferecidos.",
              },
            ],
            information: "",
            address: "Rua Marques de Paranaguá, 33 – Consolação",
            phone: "(11) 3258-6888",
            responsible: "Charles",
            email: "elianaj_@hotmail.com",
            nameUrl: "",
            url: "",
            filter: "Consolação",
            filterMoradia: "Flat",
          },
          {
            id: 49,
            title: "Bela Cintra Flat Service",
            informationList: [
              {
                id: 0,
                list_item: "- Apartamentos de 1 dormitório totalmente mobiliados.",
              },
              {
                id: 1,
                list_item: "- Limpeza e arrumação do apartamento.",
              },
              {
                id: 2,
                list_item: "- Troca de roupa de cama e banho.",
              },
              {
                id: 3,
                list_item: "- TV a cabo, cozinha americana, garagem e muito mais.",
              },
              {
                id: 4,
                list_item: "- Todas as despesas condominiais.",
              },
              {
                id: 5,
                list_item: "- Uso de computador ligado com internet.",
              },
              {
                id: 6,
                list_item: "- Mensagem e recado.",
              },
              {
                id: 7,
                list_item: "- Serviço de despertador",
              },
            ],
            information: "",
            address: "Rua Bela Cintra, 196 – Cerqueira Cesar",
            phone: "(11) 2832-8700",
            responsible: "",
            email: "depto.reservas@belacintra.com.br",
            nameUrl: "Bela Cintra Flat Service",
            url: "http://www.belacintra.com.br/",
            filter: "Consolação",
            filterMoradia: "Flat",
          },
        ],
      },

      unidades: [{ type: "Todos" }, { type: "Morumbi" }, { type: "Consolação" }, { type: "Vila Olímpia" }],

      moradias: [
        { type: "Todos" },
        { type: "Flat" },
        { type: "Studio" },
        { type: "Hotel" },
        { type: "Pensionato" },
        { type: "Imobiliária" },
      ],

      activeUnidade: "",
      activeMoradia: "",
      filteredItems: this.filteredItems,
    };
  },
  methods: {
    selectTodos(unidades, moradias) {
      this.activeTodos =
        this.activeUnidade === unidades ? "" : unidades && this.activeMoradia === moradias ? "" : moradias;
      this.applyCombinedFilter();
    },
    selectUnidade(unidades) {
      if (unidades === "Todos") {
        this.activeUnidade = "";
      } else {
        this.activeUnidade = unidades;
      }
      this.applyCombinedFilter();
    },

    selectMoradia(moradias) {
      this.activeMoradia = "";
      this.activeMoradia = moradias;
      if (moradias === "Todos") {
        this.activeMoradia = "";
      } else {
      }
      this.applyCombinedFilter();
    },

    applyCombinedFilter() {
      if (this.activeTodos === "Todos") {
        this.filteredItems = this.show_moradias.data;
      } else if (this.activeUnidade && this.activeMoradia) {
        this.filteredItems = this.show_moradias.data.filter(
          (item) => item.filter === this.activeUnidade && item.filterMoradia === this.activeMoradia
        );
      } else if (this.activeUnidade) {
        this.filteredItems = this.show_moradias.data.filter((item) => item.filter === this.activeUnidade);
      } else if (this.activeMoradia) {
        this.filteredItems = this.show_moradias.data.filter((item) => item.filterMoradia === this.activeMoradia);
      } else {
        this.filteredItems = this.show_moradias.data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-in-up {
    -webkit-animation: fadeIn 0.4s ease-in-out;
    -moz-animation: fadeIn 0.4s ease-in-out;
    -o-animation: fadeIn 0.4s ease-in-out;
    animation: fadeIn 0.4s ease-in-out;
  }
.main-moradias {
  font-family: 'Clan-Book Book', 'FF Clan Pro', sans-serif;
 
  .title_page {
    margin: 0px 0px 55px !important;
    padding: 55px 0px 50px;
    background-repeat: repeat;
    background-position: center;
    background-color: rgb(239, 72, 87);
    background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
    background-size:30%;
    background-attachment: fixed;
    border-radius: 0;

    h1 {
      font-size: 51px;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
      font-family: 'FF Clan Pro', sans-serif;
    }
  }
  .breadcrumb-navigation {
    margin: 0px 0px 0px;
    background: #f6f6f6;
    .breadcrumb-link {
      font-family: "FF Clan Pro";
      .breadcrumb-link-no_active {
        color: #333333;
        font-weight: 700;
      }
      span {
        color: #333;
        font-weight: 600;
      }
    }
  }
  .bg-search {
    margin: -120px 0px 0px;
    background-color: #fdfdfd;

    .icon-prepend {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10.5px 10px;

      background-color: transparent;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border: 1px solid transparent;

      span {
        color: #b0b0b0;
        font-weight: 700;
      }
    }

    .button-search {
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10.5px 10px;

      background-color: transparent;
      color: #b0b0b0;
      font-weight: 600;
      border: 1px solid #e3e3e3;

      &:hover {
        background-color: rgb(259,72,87);
        color: #ffffff;
      }
    }
  }

  .instruction_for_filters {
    font-family: "FF Clan Pro", sans-serif;
    margin-top: -50px;
    margin-bottom: 0px;
    padding: 40px 0px 100px;
    font-size: 1em;
    letter-spacing: -0.004em;
    background: transparent;
    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: -0.004em;
      span {
        font-size: 1.2em;
        color: rgb(58,28,83);
        font-weight: 700;
      }
    }
  }

  .nav {
    .btn-select {
      font-family: 'Clan-Book Book', sans-serif;
      span {
        color: rgb(58,28,83);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 0.975em;
      }

      .nav-link {
        padding: 0.5rem;
      }

      .nav-link.active {
        border-radius: 5px;
        padding: 7px 0.5rem;
        border: 0px solid rgb(239, 72, 87);
        background-color: rgb(239, 72, 87);
        // padding: 0.5rem 0.1rem;

        span {
          color: #ffffff;
          padding: 0px 6px;
          margin: 0px;
          width: auto;
        }
      }
    }
  }

  .card-moradias {
    margin-bottom: 20px;
    font-size: 0.875em;
    box-shadow: 4px 4px 12px #e5e5e5;
    .header-card {
      background: rgb(239, 72, 87);
      font-weight: 600;
      color: #ccc;
      display: flex;
      align-items: flex-end;
      justify-content: end;

      span {
        font-size: 1.1em;
        color: #fff;
      }
    }

    .information,
    .address,
    .telephone,
    .responsible,
    .email,
    .url {
      color: rgb(58,28,83);
      font-weight: 500;
      font-family: 'Clan-Book', sans-serif;
      .title-information {
        color: rgb(58,28,83);
        font-weight: bold;
        font-family: 'FF Clan Pro', sans-serif;
      }
      .group-list-item {
        padding: 0px;
        margin-bottom: 0px;
        background-color: transparent;
        border: 0px;
      }
    }

    .body-card {
      margin: 0px 15px;
      font-family: 'Clan-Book', sans-serif;
      .email,
      .url {
        a {
          color: rgb(239, 72, 87);
          font-weight: 600;
          font-family: 'FF Clan Pro', sans-serif;
        }
      }
    }
  }
}
</style>
