<template>
  <div class="content_header_course">
    <b-row>
      <b-col cols="12" md="12">
        <div class="product-title">
          <h2 class="font-weight-bold">
            {{ title }}
          </h2>
        </div>
      </b-col>
    </b-row>

    <div class="d-block d-md-flex pt-3 pb-3 pr-3 info-course">
      <div class="float-md-left mr-md-5 mb-3 mb-md-0">
        <div class="clearfix d-flex align-items-center">
          <div class="float-left">
            <i class="fa-solid fa-building-columns mr-4 fa-2xl"></i>
          </div>
          <div class="float-right">
            <h6 class="text-black-50 font-weight-lighter mb-0">Turma</h6>
            <span class="d-block mt-0">{{ classes }}</span>
          </div>
        </div>
      </div>
      <div class="float-md-right">
        <div class="clearfix d-flex align-items-center">
          <div class="float-left ">
            <i class="fa-solid fa-graduation-cap mr-3 fa-2xl"></i>
          </div>
          <div class="float-right">
            <h6 class="text-black-50 font-weight-lighter mb-0">Início</h6>
            <span class="d-block mt-0">{{ date | formatShortDate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "classes", "date"],
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Clan-Book Book", "FF Clan Pro", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Clan-Book Book", sans-serif;
}

span {
  font-family: "FF Clan Pro", sans-serif;
}

.content_header_course {
  margin-top: 15px;
}

.info-course i {
  color: #EE4856;
}

.info-course h6 {
  font-size: 13px;
}
</style>
