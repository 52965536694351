<template>
  <div class="main-bolsa fade-in-up mb-4">
    <b-jumbotron class="title_page">
      <h1 class="text-center font-weight-bold">Exames de Bolsa</h1>
    </b-jumbotron>
    <bread-cpv class="adjustment-breadcrumb"></bread-cpv>

    <b-container class="content-bolsa-font">
      <div class="header">
        <h1>Seu desempenho vale até <span class="emphasis">100%</span> de desconto!</h1>
      </div>
      <div class="content-bolsa">
        <p>
          O <strong>CPV</strong> oferece regularmente um <strong>Exame de Bolsas</strong> com desconto
          <strong>de até 100%</strong>, para estudantes que desejam se preparar para o vestibular. Está é a sua chance
          de garantir descontos exclusivos nas mensalidades dos nossos cursos!
        </p>
        <p>
          Com base no seu desempenho na prova, que é cuidadosamente elaborada com o conteúdo do Ensino Médio, você pode
          conquistar descontos significativos. Esses descontos são aplicados diretamente no valor contratual, tornando a
          sua preparação mais acessível e eficiente.
        </p>
        <p>
          <strong>Não perca essa oportunidade!</strong> Preencha o formulário de interesse para participar dos nossos
          <strong>Exames</strong>
          e seja o primeiro a saber sobre as novas datas.
        </p>
        <!-- <p>O Exame de Bolsa tem como finalidade analisar e avaliar o rendimento dos candidatos em relação a esta prova e valorizar os melhores desempenhos.</p>
        <span>O Exame de Bolsa será válido para todas as Turmas Semi 2º Semestre de 2024.</span> -->
        <!-- <div class="info-prova mt-4">
          <h4>As provas:</h4>
          <ul class="mt-3">
            <li>
              Dia <span>30/06/2024 (domingo) ou 06/07/2024 (sábado)</span> das <span>09h00 às 13h00</span>.
            </li>
            <li>
              Prova <span>100% On-line</span> pela <span>plataforma Alfred</span>.
            </li>
            <li>
              Exame é composto por uma <span>Redação dissertativa e 50 testes</span>, sendo <span>25 Matemática</span> e <span>25 de Língua Portuguesa</span>.
            </li>
            <li>
              A nota mínima para a <span>Redação é de 500 pontos</span>.
            </li>
            <li>
              A nota de Redação tem <span>peso 7</span> na composição da <span>Nota Final</span>.
            </li>
            <li>
              O conteúdo cobrado nas provas referente <span>aos três anos de Ensino Médio</span>.
            </li>
            <li>
              Nível de dificuldade das questões <span>similar aos simulados</span> realizados no <span>Cursinho CPV</span>.
            </li>
            <li>
              <a href="https://sitecpv.s3.amazonaws.com/new-site-cpv/2024/Regulamento-Exame-de-Bolsa-Semi-2024_2_v3.pdf">Clique aqui e confira o regulamento.</a>
            </li>
          </ul>
        </div> -->
        <div class="mt-3 d-block ml-auto mr-auto">
          <a class="btn__link" href="https://dicas.cpv.com.br/interesse-exame-de-bolsa">
            <span>Clique Aqui e Inscreva-se</span>
          </a>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import BreadCpv from "@/components/global/bread-cpv.vue";
export default {
  name: "ExameDeBolsa",
  components: { BreadCpv },
  data() {
    return {
      echoTimeout: null,
    };
  },
  created: function () {
    this.waitForEcho()
      .then(() => {
        this.joinChannel();
      })
      .catch((error) => {
        console.error("Erro ao conectar ao Echo:", error);
      });
  },
  beforeDestroy: function () {
    if (this.echoTimeout) {
      clearTimeout(this.echoTimeout);
    }
    window.Echo.leave(`cursinho.cpv.examedebolsa`);
  },
  methods: {
    waitForEcho(timeout = 10000) {
      return new Promise((resolve, reject) => {
        const startTime = Date.now();
        const checkEcho = () => {
          if (window.Echo && window.Echo.connector && window.Echo.connector.pusher) {
            console.log("Echo encontrado");
            if (window.Echo.connector.pusher.connection.state === "connected") {
              console.log("Echo conectado");
              resolve();
              return;
            }
          }

          if (Date.now() - startTime >= timeout) {
            reject(new Error("Timeout ao tentar conectar ao Echo"));
            return;
          }

          this.echoTimeout = setTimeout(checkEcho, 1000); // Verifica novamente em 100ms
        };
        checkEcho();
      });
    },
    joinChannel() {
      console.info("Tentando entrar no canal Exame de Bolsa");
      window.Echo.join(`cursinho.cpv.examedebolsa`)
        .here((users) => {
          console.info("Entrou no canal Exame de Bolsa");
        })
        .error((error) => {
          console.info("Erro ao entrar no canal Exame de Bolsa");
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-in-up {
  -webkit-animation: fadeIn 0.4s ease-in-out;
  -moz-animation: fadeIn 0.4s ease-in-out;
  -o-animation: fadeIn 0.4s ease-in-out;
  animation: fadeIn 0.4s ease-in-out;
}

.btn__link {
  display: inline-block;
  min-width: 135px;
  margin-bottom: 0;
  font-weight: 700;

  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  background-image: none;
  padding: 8px 16px;
  background: linear-gradient(
    90deg,
    rgb(239, 50, 66) 0%,
    rgb(239, 50, 66) 50%,
    rgb(239, 72, 87) 50%,
    rgb(239, 72, 87) 100%
  );
  background-size: 200% 100%;
  background-position: right;
  color: #fff;
  transition: background 0.5s ease;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 1.25px !important;
  text-align: center;
  outline: center;

  text-decoration: none;
  border-radius: 4px;
  font-size: 14px;
}

.btn__link:hover {
  background-position: left;
}

@media (min-width: 1200px) {
  .main-bolsa {
    width: 100%;
    height: auto;
  }

  .title_page {
    margin: -8px 0px 55px !important;
    padding: 55px 0px 50px;
    background-repeat: repeat;
    background-position: center;
    background-color: rgb(239, 72, 87);
    background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
    background-size: 30%;
    // background-attachment: fixed;
    object-fit: cover;
    border-radius: 0;

    h1 {
      font-size: 51px;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
      font-family: "FF Clan Pro", sans-serif;
    }
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(58, 28, 83);
  }

  .header h1 {
    font-size: 28px;
    font-weight: bold;
    line-height: 80px;
    letter-spacing: 0.81px;
    text-transform: uppercase;
  }

  .header h1 .emphasis {
    color: rgb(239, 72, 87);
  }

  .content-bolsa {
    width: 100%;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    color: #333333;
  }

  .content-bolsa span {
    font-weight: bold;
  }

  .content-bolsa .info-prova {
    width: 100%;
    height: auto;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
  }

  .content-bolsa .info-prova h4 {
    font-size: 22px;
    font-weight: bold;
  }

  .content-bolsa .info-prova ul {
    margin-left: 2%;
  }
  .content-bolsa .info-prova ul li span {
    font-weight: bold;
  }
  .content-bolsa .info-prova ul li {
    padding: 5px 0px;
  }
  .content-bolsa .info-prova ul li a {
    font-weight: bold;
    color: #333333;
    text-decoration: none;
  }
  .content-bolsa .info-prova ul li a:hover {
    font-weight: bold;
    color: rgb(239, 72, 87);
    text-decoration: none;
    transition: all 0.3s ease;
  }

  .button-inscricao .btn_insc {
    height: 56px;
    width: 35% !important;
  }

  .button-inscricao .btn_inscricao {
    border: 2px solid rgb(239, 72, 87);
    border-radius: 5px;
    background-color: rgb(239, 72, 87);
    // box-shadow: 0px 0px 30px rgba(1, 41, 141, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .button-inscricao .btn_inscricao span {
    color: #ffffff;
    text-transform: uppercase;
  }

  .content-bolsa-font {
    font-family: "Clan-Book Book", sans-serif;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .main-bolsa {
    width: 100%;
    height: auto;
    font-size: 16px;
    margin-top: 90px;
  }

  .title_page {
    margin: -8px 0px -10px;
    padding: 55px 0px 50px;
    background-repeat: repeat;
    background-position: center;
    background-color: rgb(259, 72, 87);
    background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
    border-radius: 0;
  }

  .title_page h1 {
    font-size: 41px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
  }

  .adjustment-breadcrumb {
    margin: 10px 0 50px !important;
  }

  .header {
    margin: 0px 0px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header h1 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.81px;
    text-transform: uppercase;
    text-align: center;
  }

  .header h1 .emphasis {
    color: rgb(259, 72, 87);
  }

  .content-bolsa {
    width: 100%;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    font-size: 14px;
  }

  .content-bolsa span {
    font-weight: bold;
  }

  .content-bolsa .info-prova {
    width: 100%;
    height: auto;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
  }

  .content-bolsa .info-prova h4 {
    font-size: 18px;
    font-weight: bold;
  }

  .content-bolsa .info-prova ul {
    margin-left: 4%;
  }
  .content-bolsa .info-prova ul li span {
    font-weight: bold;
  }
  .content-bolsa .info-prova ul li {
    padding: 5px 0px;
  }
  .content-bolsa .info-prova ul li a {
    font-weight: bold;
    color: #333333;
    text-decoration: none;
  }

  .button-inscricao .btn_insc {
    height: 56px;
    width: 50% !important;
  }

  .button-inscricao .btn_inscricao span {
    color: #ffffff;
    text-transform: uppercase;
  }

  .content-bolsa-font {
    font-family: "Clan-Book Book", sans-serif;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .main-bolsa {
    width: 100%;
    height: auto;
    font-size: 16px;
    margin: 80px 0;
  }

  .title_page {
    margin: -20px 0px -10px;
    padding: 55px 0px 50px;
    background-repeat: repeat;
    background-position: center;
    background-color: rgb(259, 72, 87);
    background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
    border-radius: 0;
  }

  .title_page h1 {
    font-size: 41px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    font-family: "FF Clan Pro", sans-serif;
  }

  .adjustment-breadcrumb {
    margin: 10px 0 50px !important;
  }

  .header {
    margin: 0px 0px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header h1 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.81px;
    text-transform: uppercase;
    text-align: center;
  }

  .header h1 .emphasis {
    color: rgb(259, 72, 87);
  }

  .content-bolsa {
    width: 100%;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    font-size: 14px;
  }

  .content-bolsa span {
    font-weight: bold;
  }

  .content-bolsa .info-prova {
    width: 100%;
    height: auto;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
  }

  .content-bolsa .info-prova h4 {
    font-size: 18px;
    font-weight: bold;
  }

  .content-bolsa .info-prova ul {
    margin-left: 4%;
  }
  .content-bolsa .info-prova ul li span {
    font-weight: bold;
  }
  .content-bolsa .info-prova ul li {
    padding: 5px 0px;
  }
  .content-bolsa .info-prova ul li a {
    font-weight: bold;
    color: #333333;
    text-decoration: none;
  }

  .button-inscricao .btn_insc {
    height: 56px;
    width: 50% !important;
  }

  .button-inscricao .btn_inscricao span {
    color: #ffffff;
    text-transform: uppercase;
  }

  .content-bolsa-font {
    font-family: "Clan-Book Book", sans-serif;
  }
}

@media only screen and (min-width: 578px) and (max-width: 768px) {
  .main-bolsa {
    width: 100%;
    height: auto;
    font-family: "Clan-Book Book", sans-serif;
    font-size: 16px;
  }

  .title_page {
    margin: -10px 0px -10px;
    padding: 55px 0px 50px;
    background-repeat: repeat;
    background-position: center;
    background-color: rgb(259, 72, 87);
    background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
    border-radius: 0;
  }

  .title_page h1 {
    font-size: 41px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
  }

  .adjustment-breadcrumb {
    margin-top: 0px !important;
  }

  .header {
    margin: 0px 0px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header h1 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.81px;
    text-transform: uppercase;
    text-align: center;
  }

  .header h1 .emphasis {
    color: rgb(259, 72, 87);
  }

  .content-bolsa {
    width: 100%;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    font-size: 14px;
  }

  .content-bolsa span {
    font-weight: bold;
  }

  .content-bolsa .info-prova {
    width: 100%;
    height: auto;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
  }

  .content-bolsa .info-prova h4 {
    font-size: 18px;
    font-weight: bold;
  }

  .content-bolsa .info-prova ul {
    margin-left: 4%;
  }
  .content-bolsa .info-prova ul li span {
    font-weight: bold;
  }
  .content-bolsa .info-prova ul li {
    padding: 5px 0px;
  }
  .content-bolsa .info-prova ul li a {
    font-weight: bold;
    color: #333333;
    text-decoration: none;
  }

  .button-inscricao .btn_insc {
    height: 56px;
    width: 100% !important;
  }

  .button-inscricao .btn_inscricao span {
    color: #ffffff;
    text-transform: uppercase;
  }

  .content-bolsa-font {
    font-family: "Clan-Book Book", sans-serif;
  }
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  .main-bolsa {
    width: 100%;
    height: auto;
    font-size: 16px;
  }

  .title_page {
    margin: -8px 0px 0px;
    background-repeat: repeat;
    background-position: center;
    background-color: rgb(255, 72, 87);
    background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
    border-radius: 0;
  }

  .title_page h1 {
    font-size: 41px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    font-family: "FF Clan Pro", sans-serif;
  }

  .header {
    margin: 0px 0px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header h1 {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.81px;
    text-transform: uppercase;
    text-align: center;
  }

  .header h1 .emphasis {
    color: rgb(259, 72, 87);
  }

  .adjustment-breadcrumb {
    margin-top: 0px !important;
  }

  .content-bolsa {
    width: 100%;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
    font-size: 14px;
  }

  .content-bolsa span {
    font-weight: bold;
  }

  .content-bolsa .info-prova {
    width: 100%;
    height: auto;
    display: flex;
    align-items: left;
    justify-content: left;
    flex-direction: column;
  }

  .content-bolsa .info-prova h4 {
    font-size: 18px;
    font-weight: bold;
  }

  .content-bolsa .info-prova ul {
    margin-left: 4%;
  }
  .content-bolsa .info-prova ul li span {
    font-weight: bold;
  }
  .content-bolsa .info-prova ul li {
    padding: 5px 0px;
  }
  .content-bolsa .info-prova ul li a {
    font-weight: bold;
    color: #333333;
    text-decoration: none;
  }

  .button-inscricao .btn_insc {
    height: 56px;
    width: 75% !important;
  }

  .button-inscricao .btn_inscricao span {
    color: #ffffff;
    text-transform: uppercase;
  }

  .content-bolsa-font {
    font-family: "Clan-Book Book", sans-serif;
  }
}
</style>
