<template>
  <div class="main-aprovados">
    <b-jumbotron class="title-page mb-5 mt-5 title_page">
      <h1 class="text-center font-weight-bold">Aprovados</h1>
    </b-jumbotron>
    <bread-cpv class="adjustment-breadcrumb"></bread-cpv>

    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          <div class="text-center mt-5 mb-5">
            <img
              width="500"
              height="85"
              src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/aprovados.webp"
              alt="Aprovados CPV"
              class="img-fluid"
            />
          </div>
        </div>
      </div>

      <!-- APROVADOS 2021 -->

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          <div class="content-title-aprovados">
            <h2 class="card-title text-center">1080 APROVAÇÕES DE QUEM FEZ CPV EM 2021</h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-usp-unicamp mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/logo-usp-unicamp-unesp.webp"
                      alt="USP, UNICAMP, UNESP"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">75</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-medicinas-fmabc mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/logo-medicinas-22-1.webp"
                      alt="Medicina"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">39</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-faap-inteli mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/outras-faculdades-2022.webp"
                      alt="Outras faculdades"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">30</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  2ª COLUMN -->

      <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-lg-3">
          <div class="card bg-card-aprovados card-aprovados card-fgv mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/fgvlogo.webp"
                      alt="Fundação Getulio Vargas"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">238</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-3">
          <div class="card bg-card-aprovados card-aprovados card-insper mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/insper-logo.webp"
                      alt="Inper"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">262</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-3">
          <div class="card bg-card-aprovados card-ibmec card-aprovados mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="150"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/ibmec-1.webp"
                      alt="Ibmec"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">138</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-3">
          <div class="card bg-card-aprovados card-lsb card-aprovados mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/logo-link-aprovados.webp"
                      alt="LSB Link School of Business"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">45</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-espm mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/espm2.webp"
                      alt="ESPM"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">53</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-mack mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/mack2.webp"
                      alt="MACK"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">116</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-puc-sp mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/puc-sp1.webp"
                      alt="PUC-SP"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">84</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 881 APROVAÇÕES DE QUEM FEZ CPV EM 2020 -->

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          <div class="content-title-aprovados">
            <h2 class="card-title text-center">881 APROVAÇÕES DE QUEM FEZ CPV EM 2020</h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-usp-unicamp mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/logo-usp-unicamp-unesp.webp"
                      alt="USP, UNICAMP, UNESP"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">42</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-medicinas-fmabc mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/logo-medicinas-21-1.webp"
                      alt="Medicina"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">2</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-faap-inteli mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/outras-faculdades.jpg"
                      alt="Outras faculdades"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">25</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  2ª COLUMN -->

      <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-fgv mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/fgvlogo.webp"
                      alt="Fundação Getulio Vargas"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">269</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-insper mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/insper-logo.webp"
                      alt="Inper"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">236</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-ibmec card-aprovados mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="150"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/ibmec-1.webp"
                      alt="Ibmec"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">81</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-espm mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/espm2.webp"
                      alt="ESPM"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">101</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-puc-sp mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/puc-sp1.webp"
                      alt="PUC-SP"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">53</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-mack mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/mack2.webp"
                      alt="MACK"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">72</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 1463 APROVAÇÕES DE QUEM FEZ CPV EM 2019 -->

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          <div class="content-title-aprovados">
            <h2 class="card-title text-center">1463 APROVAÇÕES DE QUEM FEZ CPV EM 2019</h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-usp-unicamp mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/logo-usp-unicamp-unesp.webp"
                      alt="USP, UNICAMP, UNESP"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">83</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-medicinas-fmabc mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/logo-medicinas-20-1.webp"
                      alt="Medicina"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">26</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-faap-inteli mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/outras-faculdades.jpg"
                      alt="Outras faculdades"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">79</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  2ª COLUMN -->

      <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-fgv mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/fgvlogo.webp"
                      alt="Fundação Getulio Vargas"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">351</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-insper mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/insper-logo.webp"
                      alt="Inper"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">348</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-ibmec card-aprovados mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="150"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/ibmec-1.webp"
                      alt="Ibmec"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">182</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-espm mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/espm2.webp"
                      alt="ESPM"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">126</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-puc-sp mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/puc-sp1.webp"
                      alt="PUC-SP"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">161</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-mack mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/mack2.webp"
                      alt="MACK"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">107</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 1375 APROVAÇÕES DE QUEM FEZ CPV EM 2018 -->

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          <div class="content-title-aprovados">
            <div class="content-title-aprovados">
              <h2 class="card-title text-center">1375 APROVAÇÕES DE QUEM FEZ CPV EM 2018</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-usp-unicamp mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/logo-usp-unicamp-unesp.webp"
                      alt="USP, UNICAMP, UNESP"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">73</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-medicinas-fmabc mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/logo-medicinas-19-1.webp"
                      alt="Medicina"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">15</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-faap-inteli mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/logo-outras-faculdades-21-1.webp"
                      alt="Outras faculdades"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">66</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  2ª COLUMN -->

      <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-fgv mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/fgvlogo.webp"
                      alt="Fundação Getulio Vargas"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">380</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-insper mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/insper-logo.webp"
                      alt="Inper"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">329</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-ibmec card-aprovados mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="150"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/ibmec-1.webp"
                      alt="Ibmec"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">124</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-espm mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/espm2.webp"
                      alt="ESPM"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">188</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-puc-sp mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/puc-sp1.webp"
                      alt="PUC-SP"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">115</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-mack mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/mack2.webp"
                      alt="MACK"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">85</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 1345 APROVAÇÕES DE QUEM FEZ CPV EM 2017 -->

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          <div class="content-title-aprovados">
            <h2 class="card-title text-center">1345 APROVAÇÕES DE QUEM FEZ CPV EM 2017</h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-usp-unicamp mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/logo-usp-unicamp-unesp.webp"
                      alt="USP, UNICAMP, UNESP"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">102</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-medicinas-fmabc mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/logo-medicinas-18-1.webp"
                      alt="Medicina"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">5</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-faap-inteli mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/logo-outras-faculdades-21-1.webp"
                      alt="Outras faculdades"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">48</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  2ª COLUMN -->

      <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-fgv mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/fgvlogo.webp"
                      alt="Fundação Getulio Vargas"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">329</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-insper mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/insper-logo.webp"
                      alt="Inper"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">305</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-ibmec card-aprovados mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="150"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/ibmec-1.webp"
                      alt="Ibmec"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">63</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-espm mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/espm2.webp"
                      alt="ESPM"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">211</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-puc-sp mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/puc-sp1.webp"
                      alt="PUC-SP"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">175</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-mack mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/mack2.webp"
                      alt="MACK"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">107</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 1277 APROVAÇÕES EM 2017 -->

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          <div class="content-title-aprovados">
            <h2 class="card-title text-center">1277 APROVAÇÕES EM 2017</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-fgv mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/fgvlogo.webp"
                      alt="Fundação Getulio Vargas"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">330</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-insper mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/insper-logo.webp"
                      alt="Inper"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">302</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-usp-unicamp card-aprovados mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/espm2.webp"
                      alt="Ibmec"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">271</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-espm mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/puc-sp1.webp"
                      alt="PUC-SP"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">109</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-puc-sp mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/mack2.webp"
                      alt="MACK"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">185</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-mack mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/variasuni.webp"
                      alt="MACK"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">80</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 839 APROVAÇÕES EM 2016 -->

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          <div class="content-title-aprovados">
            <h2 class="card-title text-center">839 APROVAÇÕES EM 2016</h2>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-fgv mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/fgvlogo.webp"
                      alt="Fundação Getulio Vargas"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">295</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-insper mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/insper-logo.webp"
                      alt="Inper"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">278</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-usp-unicamp card-aprovados mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/espm2.webp"
                      alt="Ibmec"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">287</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-espm mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/puc-sp1.webp"
                      alt="PUC-SP"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">210</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-puc-sp mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/mack2.webp"
                      alt="MACK"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">93</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
          <div class="card bg-card-aprovados card-aprovados card-mack mb-5 mt-3">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="text-center">
                    <img
                      width="200"
                      height="48"
                      src="https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/aprovados/fuvest.webp"
                      alt="MACK"
                      class="mt-3 img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <h2 class="text-center mt-4 mb-3 text-white" style="font-size: 2.3rem">109</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCpv from '@/components/global/bread-cpv.vue';

export default {
  nome: "aprovadoCPV",
  metaInfo: { title: 'Aprovados' },
  components: { BreadCpv },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.main-aprovados {
 .title_page {
    margin: 0px 0px 55px !important;
    padding: 55px 0px 50px;
    background-repeat: repeat;
    background-position: center;
    background-color: rgb(239, 72, 87);
    background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
    background-size:30%;
    background-attachment: fixed;
    border-radius: 0;

    h1 {
      font-size: 51px;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
    }
  }

h1 {
  font-weight: 600;
  letter-spacing: 1.3px;
}

.content-title-aprovados {
  font-family: 'Clan Book', sans-serif;
  h2 {
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 1.3px;
    color: rgb(239,72,87) !important;
  }
}

.row-2-column {
  margin-top: -30px;
  margin-bottom: 50px;
}

.bg-card-aprovados {
  border: none;
  box-shadow: 4px 4px 12px rgb(167, 167, 167);
}

.card-usp-unicamp {
  background-color: #d16a25 !important;
  border-radius: 35px !important;
}

.card-medicinas-fmabc {
  background-color: #1e73be !important;
  border-radius: 35px !important;
}

.card-faap-inteli {
  background-color: #1699ad !important;
  border-radius: 35px !important;
}

.card-fgv {
  background-color: #58932d !important;
  border-radius: 35px !important;
}

.card-insper {
  background-color: #8224e3 !important;
  border-radius: 35px !important;
}

.card-ibmec {
  background-color: #d38230 !important;
  border-radius: 35px !important;
}

.card-lsb {
  background-color: #f1dc63 !important;
  border-radius: 35px !important;
}

.card-espm {
  background-color: #e8a23c !important;
  border-radius: 35px !important;
}

.card-mack {
  background-color: #26460f !important;
  border-radius: 35px !important;
}

.card-puc-sp {
  background-color: #1517a3 !important;
  border-radius: 35px !important;
}
}
</style>
