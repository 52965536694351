<template>
  <div class="main-contato fade-in-up">
    <b-jumbotron class="title-page mb-5 mt-5 title_page">
      <h1 class="text-center font-weight-bold">Contato</h1>
    </b-jumbotron>
    <bread-cpv class="adjustment-breadcrumb"></bread-cpv>

    <b-container>
    <div class="box-contact">
      <div class="box">
          <b-row>
            <b-col md="1"></b-col>
            <b-col cols="12" md="5">
              <div class="box-content">
                <i class="fa-duotone fa-phone"></i>
                <div>
                  <h3>Vila Olímpia</h3>
                  <p class="mt-0 mb-0">Rua Fiandeiras, 964 - São Paulo - SP</p>
                  <p>Telefone: <span class="strong">(11) 3045-5515</span></p>
                </div>
              </div>
            </b-col>
            <b-col cols="12" md="5">
              <div class="box-content">
                <i class="fa-duotone fa-phone"></i>
                <div>
                  <h3>Consolação</h3>
                  <p class="mt-0 mb-0">Rua da Consolação, 1705 - São Paulo - SP</p>
                  <p>Telefone: <span class="strong">(11) 3123-4590</span></p>
                </div>
              </div>
            </b-col>
            <b-col md="1"></b-col>
          </b-row>
          <hr class="mt-4">
      </div>
    </div>

    <div class="box-title">
        <b-row>
          <b-col cols="12">
            <h3 class="text-center text-uppercase font-weight-bold">Para mais informações, utilize o formulário abaixo</h3>
          </b-col>
        </b-row>
    </div>

   <div class="box-form">
    <b-form @submit.prevent="handleContact">
      <b-card
        class="mt-3"
        header-bg-variant="transparent"
        footer-bg-variant="trasparent"
        header-class="d-flex justify-content-between align-items-center"
      >
        <template v-slot:header>
          <h5 class="mb-0">Dados Pessoais</h5>
        </template>

        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group 
              label="Nome" 
              label-for="contato_nome" 
              label-class="font-weight-bold required-field"
            >
              <b-form-input 
                name="contato_nome" 
                id="contato_nome" 
                type="text" 

              />
              <b-form-invalid-feedback
                :force-show="submitStatus == 'ERROR' && 
                $v.contato.nome.$invalid && 
                !$v.contato.nome.required"
              >
                Campo Obrigatório!
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                :force-show="submitStatus == 'ERROR' && 
                $v.contato.nome.$invalid && 
                !$v.contato.nome.alpha"
              >
                O campo aceita apenas caracteres de a-Z, números e espaços.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group
              label="Celular"
              label-for="contato_celular"
              label-class="font-weight-bold required-field"
            >
              <the-mask
                id="contato_celular"
                name="contato_celular"
                :mask="['(##) #####-####']"
                type="text"
                placeholder="(##) #####-####"
                class="form-control"
              />
              <b-form-invalid-feedback
                :force-show="submitStatus == 'ERROR' && 
                $v.contato.telephone.$invalid && 
                !$v.contato.telephone.required"
              >
                Campo Obrigatório.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="6">
            <b-form-group
              label="E-mail"
              label-for="contato_email"
              label-class="font-weight-bold required-field"
            >
              <b-form-input
                name="contato_email"
                id="contato_email"
                type="text"
              />
              <b-form-invalid-feedback
                :force-show="submenuStatus == 'ERROR' && 
                $v.contato.email.$invalid && 
                !$v.contato.email.required"
              >
                Campo Obrigatório
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="6">
            <b-form-group
                label="Como conheceu o CPV?"
                label-for="contato_conheceu_cpv"
                label-class="font-weight-bold required-field"
              >
                <b-form-input
                  name="contato_conheceu_cpv"
                  id="contato_conheceu_cpv"
                  type="text"
                  
                />
                <b-form-invalid-feedback
                  :force-show="
                  submitStatus == 'ERROR' && 
                  $v.contato.como_conheceu.$invalid && 
                  !$v.contato.como_conheceu.alpha"
                >
                  O campo aceita apenas caracteres de a-Z, números e espaços.
                </b-form-invalid-feedback>
              </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
             <b-form-group
                label="Colégio Atual"
                label-for="contato_conheceu_cpv"
                label-class="font-weight-bold required-field"
              >
                <b-form-input
                  name="contato_conheceu_cpv"
                  id="contato_conheceu_cpv"
                  type="text"
                  
                />
                <b-form-invalid-feedback
                  :force-show="
                  submitStatus == 'ERROR' && 
                  $v.contato.colegio_atual.$invalid && 
                  !$v.contato.colegio_atual.alpha"
                >
                  O campo aceita apenas caracteres de a-Z, números e espaços.
                </b-form-invalid-feedback>
              </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group
              label-for="contato_e_aluno_cpv"
              label-class="font-weight-bold"
              class="mt-1"
            >
              <template #label> &nbsp; </template>
              <b-form-checkbox
                name="contato_e_aluno_cpv"
                id="contato_e_aluno_cpv"
                size="lg"

              >
                <small class="font-weight-bold" style="font-size: 16px;">Sou aluno do CPV</small>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="mt-3"
        header-bg-variant="transparent"
        footer-bg-variant="transparent"
        header-class="d-flex justify-content-between align-items-center"  
        footer-class="d-flex justify-content-between align-items-center"
      >
        <template v-slot:header>
          <h5 class="mb-0">Curso e Carreira de Interesse</h5>
        </template>

        <b-row>
          <b-col cols="12" md="4">
            <b-form-group
              label="Curso"
              label-for="contato_curso"
              label-class="font-weight-bold required-field"
            >
              <b-form-select
                id="contato_curso"
                name="contato_curso"
              >
                <option value="" disabled>Selecione o curso de interesse</option>
                <option value="">Extensivo</option>
                <option value="">Semi</option>
                <option value="">Intensivo</option>
                <option value="">Revisão</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
             <b-form-group
              label="Área"
              label-for="contato_area"
              label-class="font-weight-bold required-field"
            >
              <b-form-select
                id="contato_area"
                name="contato_area"
              >
                <option value="" disabled>Selecione a área de interesse</option>
                <option value="">Humanas</option>
                <option value="">Exatas</option>
                <option value="">Biomédicas</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
              label="Carreira"
              label-for="contato_carreira"
              label-class="font-weight-bold required-field"
            >
              <b-form-select
                id="contato_carreira"
                name="contato_carreira"
              >
                <option value="" disabled>Selecione a carreira de interesse</option>
                <option value="">Arquitetura</option>
                <option value="">Administração</option>
                <option value="">Administração Pública</option>
                <option value="">Ciências Sociais</option>
                <option value="">Direito</option>
                <option value="">Economia</option>
                <option value="">Engenharia</option>
                <option value="">Jornalismo</option>
                <option value="">Medicina</option>
                <option value="">Nutrição</option>
                <option value="">Psicologia</option>
                <option value="">Publicidade e Propaganda</option>
                <option value="">Relações Internacionais</option>
                <option value="">Sistemas de Informação</option>
                <option value="">Outros</option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="mt-3"
        header-bg-variant="transparent"
        footer-bg-variant="transparent"
        header-class="d-flex justify-content-between align-items-center"
        footer-class="d-flex justify-content-between align-items-center"
      >
        <template v-slot:header>
          <h5 class="mb-0">Mensagem</h5>
        </template>

        <b-row>
          <b-col col="12" md="12">
            <b-form-group
              label-for="contato_mensagem"
              label-class="font-weight-bold"
            >
              <b-form-textarea
                id="contato_mensagem"
                placeholder="Deixe sua mensagem, dúvida ou sugestão!"
                rows="10"
                max-rows="10"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
         <template v-slot:footer>
            <b-link  class="text-primary btn btn-send">
              <i class="fa-duotone fa-right-to-bracket mr-2"></i> <span>Enviar</span>
            </b-link>
          </template>
      </b-card>
    </b-form>
   </div>
  </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import BreadCpv from "@/components/global/bread-cpv"
import { 
  FormPlugin, 
  FormGroupPlugin, 
  FormSelectPlugin,
  FormInputPlugin,
  FormTextareaPlugin,
  FormCheckboxPlugin 
} from "bootstrap-vue";
import ApiService from '@/common/api.service';
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormCheckboxPlugin);

import { TheMask } from "vue-the-mask";
import { required, email, minLength, maxLength, numeric } from 'vuelidate/lib/validators';
import { helpers } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /^[a-zA-ZÀ-ü0-9' ]*$/);

export default {
  name: "contato",
  components: { BreadCpv, TheMask },
  data() {
    return {
      submenuStatus: null,
      submiting: false,
      contato: {
        nome: null,
        email: null,
        telephone: null,
        curso: false,
        area: false,
        carreira: false,
        como_conheceu: false,
        outros: null,
        colegio_atual: null,
        mensagem: null
      }
    }
  },
  computed: {
    validations: {
      contato: {
        nome: {
          required,
          alpha
        },
        telephone: {
          required,
          numeric,
          minLength: minLength(11),
          masLength: maxLength(11),
        },
        email: {
          email,
          required
        },
        // como_conheceu: {
        //   alpha
        // },
        // area: {
        //   required
        // },
        // curso: {
        //   required
        // },
        // carreira: {
        //   required
        // },
        // colegio_atual: {
        //   required
        // }
      }
    }
  }, 
methods: {
  handleContact(e) {
    this.$v.$touch();
    if (this.$v.invalid) {
      this.submitStatus = "ERROR";
    } else {
      this.submitStatus = "PENDING";
      setTimeout(() => {
        this.submit();
        this.submitStatus = "OK";
      }, 500);
    }
  },
  submit: async function() {
    this.submiting = true;
    await ApiService.post("", {
      contato: this.contato,
    })
      .then((response) => {
        if (response.data.status == "success") {
          this.$snotify.success("Formulário de contato enviado com sucesso");
          this.contato = {
            nome: null,
            email: null,
            telephone: null,
            curso: false,
            area: false,
            carreira: false,
            como_conheceu: false,
            outros: null,
            colegio_atual: null,
            mensagem: null
          };
          this.$v.$reset();
        } else {
          this.$snotify.error("Ocorreu um erro.")
        }
      })
      .finally(() => {
        this.submiting = false;
      })
      .catch((errors) => {
        console.log(errors);
        this.$snotify.error("Ocorreu um erro.")
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-in-up {
    -webkit-animation: fadeIn 0.4s ease-in-out;
    -moz-animation: fadeIn 0.4s ease-in-out;
    -o-animation: fadeIn 0.4s ease-in-out;
    animation: fadeIn 0.4s ease-in-out;
  }
  
.main-contato {
  font-family: 'Open Sans';
  .title_page {
    font-family: 'Montserrat';
    margin: 0px 0px -10px;
    padding: 55px 0px 50px;
    background-repeat: repeat;
    background-position: center;
    background-color: rgb(259,72,87);
    background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
    border-radius: 0;
    
    h1 {
      font-size: 51px;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
    }
  }

  .box-contact {
    padding: 30px 0px 60px;
    .box {
      .box-content {
        padding: 50px 0px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 30px rgba(1, 41, 112, 0.08);
        border-top-left-radius: 32px;
        border-bottom-right-radius: 32px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;

        i {
          font-size: 46px;
          line-height: 0;
          margin-right: 20px;
          color: rgb(259,72,87);
        }

        div {
          h3 {
            font-size: 26px;
            text-transform: uppercase;
            font-weight: 700;
            color: rgb(259,72,87);
          }

          p {
            font-size: 15px;
            .strong {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .box-title {
    padding: 10px 0px 20px;
    h3 {
      font-size: 24px;
      color: #333333;
    }
  }

  .box-form {
    padding: 70px 0px 60px;

    .test {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .btn-send {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 225px;
    height: 56px;
    background-color: rgb(259,72,87);

    i, span {
      font-size: 18px;
      
      text-transform: uppercase;
    }
    
    i {
      color: #fff;
    }

    span {
      color: #fff;
      font-weight: 650;
      letter-spacing: 0.01em;
    }

    &:hover {
      background: #f14c00;
      transition: all 0.3s ease-in-out;
    }
  }
}
  
</style>