<template>
  <div>
    <cpv-header></cpv-header>
    <div id="content" class="main-content">
      <router-view />
    </div>
    <cpv-footer :footer-type="'grupo'"></cpv-footer>
    <vue-snotify></vue-snotify>
    <WhatsappButton />
  </div>
</template>

<script>
import cpvHeader from "@/components/global/header.vue";
import cpvFooter from "@/components/global/newFooter";

export default {
  metaInfo: {
    title: "Cursinho CPV",
    titleTemplate: "%s - Cursinho CPV",
  },
  components: {
    cpvHeader,
    cpvFooter,
  },
  computed: {},
  data() {
    return {
      echoTimeout: null,
    };
  },
  created: function () {
    this.waitForEcho()
      .then(() => {
        this.joinChannel();
      })
      .catch((error) => {
        console.error("Erro ao conectar ao Echo:", error);
      });
  },
  beforeDestroy: function () {
    if (this.echoTimeout) {
      clearTimeout(this.echoTimeout);
    }
    window.Echo.leave(`cursinho.cpv.main`);
  },
  mounted() {},
  watch: {},
  methods: {
    waitForEcho(timeout = 10000) {
      return new Promise((resolve, reject) => {
        const startTime = Date.now();
        const checkEcho = () => {
          if (window.Echo && window.Echo.connector && window.Echo.connector.pusher) {
            console.log("Echo encontrado");
            if (window.Echo.connector.pusher.connection.state === "connected") {
              console.log("Echo conectado");
              resolve();
              return;
            }
          }

          if (Date.now() - startTime >= timeout) {
            reject(new Error("Timeout ao tentar conectar ao Echo"));
            return;
          }

          this.echoTimeout = setTimeout(checkEcho, 1000); // Verifica novamente em 100ms
        };
        checkEcho();
      });
    },
    joinChannel() {
      console.info("Tentando entrar no canal Cursinho CPV");
      window.Echo.join(`cursinho.cpv.main`)
        .here((users) => {
          console.info("Entrou no canal Cursinho CPV");
        })
        .error((error) => {
          console.info("Erro ao entrar no canal Cursinho CPV");
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.main-content {
  font-family: "Clan-Book Book", "FF Clan Pro", sans-serif !important;
}
.viewFooter {
  display: block;
}
.hideFooter {
  display: none;
}
@media (min-width: 1200px) {
  .main-content {
    margin-top: 95px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .main-content {
    margin-top: 95px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .main-content {
    margin-top: 70px;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .main-content {
    margin-top: 70px;
  }
}
@media (min-width: 320px) and (max-width: 576px) {
  .main-content {
    margin-top: 70px;
  }
}
</style>
