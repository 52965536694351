<template>
    <div class="card-navigation">
        <b-row class="no-gutters">
            <b-col
                cols="12"
                sm="12"
                md="3"
                lg="3"
                xl="3"
                v-for="(cards, index) in cardsNav"
                :key="index"
            >
                <div class="general-cards" :class="bgCards(cards.name)">
                    <b-link
                        :href="cards.url"
                        class="link-url"
                    >
                        <div class="cards">
                            <div class="icons">
                                <i :class="cards.icon"></i>
                            </div>
                            <div class="name-cards">
                                <span>{{ cards.name }}</span>
                            </div>
                        </div>
                    </b-link>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "CardCursinhoCPV",
    computed: {
        bgCards() {
            return (name) => {
                if (name == "Próximas Turmas") {
                    return {
                        fundamental: true,
                    };
                } else if (name == "Vestibulares Resolvidos") {
                    return {
                        medio: true,
                    };
                } else if (name == "E-book Vestibulares") {
                    return {
                        cursinho: true,
                    };
                } else if (name == "Simulados Abertos") {
                    return {
                        sistema: true,
                    };
                } else {
                    console.log("Error in BACKGROUND-COLOR.");
                }
            };
        },
        heightCards() {
          return {
            // height: `${this.cardsHeight}px`,
          };
        },
    },
    data: () => {
        return {
            cardsNav: [
                {
                    name: "Próximas Turmas",
                    icon: "fa-solid fa-school",
                    url: "/proximasturmas",
                },
                {
                    name: "Vestibulares Resolvidos",
                    icon: "fa-duotone fa-graduation-cap",
                    url: "/cpv-resolve",
                },
                {
                    name: "E-book Vestibulares",
                    icon: "fa-duotone fa-chalkboard-user",
                    url: "/ebook",
                },
                {
                    name: "Simulados Abertos",
                    icon: "fa-duotone fa-books",
                    url: "/simulados-abertos-cpv",
                },
            ],
        };
    },
    methods: {},
};
</script>

<style scoped>

@media only screen
    and (min-width: 1200px) {
    .card-navigation {
        margin: 100px 0px 0px;
    }

    .general-cards {
        border-radius: 8px;
        margin: 0px 0px 0px 0px;
    }

    .general-cards:hover {
        margin-top: -10px;
        transition: all 0.3s ease-in-out;
    }

    .link-url {
        text-decoration: none !important;
    }

    .cards {
        margin: 0;
        padding: 30px 50px;

        text-align: center;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .cards .icons {
        line-height: 58px;
    }

    .cards .icons > i {
        color: #fff;
        font-size: 3em;
    }

    .cards .name-cards > span {
        text-transform: uppercase;
        letter-spacing: -0.004em;
        font-weight: 700;

        color: #ffffff;
        font-size: 1.3em !important;
        text-align: center;
    }

    .no-gutters {
        margin-right: -20px;
        margin-left: -20px;
    }
}

@media only screen
    and (min-width: 992px)
    and (max-width: 1200px) {
    .card-navigation {
        margin: 15px 0px 0px;
    }

    .general-cards {
        border-radius: 8px;
        margin: 0px 0px 0px 0px;
    }

    .general-cards:hover {
        margin-top: -10px;
        transition: all 0.3s ease-in-out;
    }

    .link-url {
        text-decoration: none !important;
    }

    .cards {
        margin: 0;
        padding: 30px 40px;

        text-align: center;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .cards .icons {
        line-height: 58px;
    }

    .cards .icons > i {
        color: #fff;
        font-size: 3em;
    }

    .cards .name-cards > span {
        text-transform: uppercase;
        letter-spacing: -0.004em;
        font-weight: 700;

        color: #ffffff;
        font-size: 1.3em !important;
        text-align: center;
    }

    .no-gutters {
        margin-right: -20px;
        margin-left: -20px;
    }
}

@media only screen
    and (min-width: 768px)
    and (max-width: 992px) {
    .card-navigation {
        margin: 0px 0px 0px;
    }

    .general-cards {
        border-radius: 8px;
        margin: 0px 0px 0px 0px;
    }

    .general-cards:hover {
        margin-top: -10px;
        transition: all 0.3s ease-in-out;
    }

    .link-url {
        text-decoration: none !important;
    }

    .cards {
        margin: 0;
        padding: 30px 40px;

        text-align: center;

        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .cards .icons {
        line-height: 58px;
    }

    .cards .icons > i {
        color: #fff;
        font-size: 3em;
    }

    .cards .name-cards > span {
        text-transform: uppercase;
        letter-spacing: -0.004em;
        font-weight: 700;

        color: #ffffff;
        font-size: 1.3em !important;
        text-align: center;
    }

    .no-gutters {
        margin-right: -20px;
        margin-left: -20px;
    }
}

@media only screen
    and (min-width: 576px) 
    and (max-width: 768px) {
    .card-navigation {
        margin: -15px 0px -15px;
        /* width: 100vw; */
    }

    .link-url {
        text-decoration: none !important;
    }

    .cards {
        margin: 0px;
        padding: 50px 0px 40px;

        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        /* width: 100vw; */
    }


    .cards .icons {
        line-height: 4.8em;
    }

    .cards .icons > i {
        color: #fff;
        font-size: 3.2em;
    }

    .cards .name-cards {
        line-height: 1.8005em;
    }

    .cards .name-cards > span {
        text-transform: uppercase;
        letter-spacing: -0.004em;
        font-weight: 700;
        color: #ffffff;
        font-size: 1.3em;
    }
}

@media only screen
    and (min-width: 320px)
    and (max-width: 576px) {
    .card-navigation {
        margin: 30px 0px -50px;
    }

    .link-url {
        text-decoration: none !important;
    }

    .cards {
        margin: 0px;
        padding: 50px 0px 40px;

        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .cards .icons {
        line-height: 4.8em;
    }

    .cards .icons > i {
        color: #fff;
        font-size: 3.2em;
    }

    .cards .name-cards {
        line-height: 1.8005em;
    }

    .cards .name-cards > span {
        text-transform: uppercase;
        letter-spacing: -0.004em;
        font-weight: 700;
        color: #ffffff;
        font-size: 1.3em;
    }
}

/* 
  Defined background-color cards navigation
*/
.fundamental {
    background-color: #1c745f;
    text-align: center;
}

.medio {
    background-color: #d74c21;
    text-align: center;
}

.cursinho {
    background-color: #42c1a0;
    text-align: center;
}

.sistema {
    background-color: #ff6622;
    text-align: center;
}
</style>