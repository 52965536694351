<template>
  <div>
    <b-jumbotron class="title-page mb-5 title_page ">
      <h1 class="text-center font-weight-bold">Provas e Gabaritos - ENEM</h1>
    </b-jumbotron>
    <bread-cpv></bread-cpv>

    <b-container>
      <header>
        <h5 class="text-center mb-4">Escolha o ano desejado, preencha o seu cadastro e receba as provas e os gabaritos no seu e-mail.</h5>
      </header>
      <b-row>
        <b-col cols="6" sm="6" md="4" lg="2"  v-for="(item, index) in items" :key="index">
            <div class="center-column">
              <b-link :href="item.link_url" class="btn-default">
                {{ item.name }}
              </b-link>
            </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import BreadCpv from "@/components/global/bread-cpv"

export default {
  name: "Provas e Gabaritos - Enem",
  metaInfo: { title: 'Vestibular Resolvido Enem' },
  components: { BreadCpv },
  data() {
    return {
      title: "ENEM",
      items: [
        {
          id: 0,
          name: "2023",
          link_url: "https://dicas.cpv.com.br/vestibular-enem-resolvido-2023",
        },
        {
          id: 1,
          name: "2020",
          link_url: "https://dicas.cpv.com.br/vestibular-enem-resolvido-2020",
        },
        {
          id: 2,
          name: "2019",
          link_url: "https://dicas.cpv.com.br/enem-2019-resolvido",
        },
        {
          id: 3,
          name: "2018",
          link_url: "https://dicas.cpv.com.br/enem-2018-resolvido",
        },
        {
          id: 4,
          name: "2017",
          link_url: "http://dicas.cpv.com.br/enem-2017-resolvido",
        },
        {
          id: 5,
          name: "2016",
          link_url: "http://dicas.cpv.com.br/enem-2016-resolvido",
        },
        {
          id: 6,
          name: "2015",
          link_url: "http://dicas.cpv.com.br/enem-2015-resolvido",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
 .title_page {
    margin: -10px 0px -10px;
    padding: 55px 0px 50px;
    background-repeat: repeat;
    background-position: center;
    background-color: rgb(259,72,87);
    background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/cpv-fundo-4.png");
    border-radius: 0;

    h1 {
      font-size: 51px;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
    }
  }

  .header {
    margin: 0px 0px 50px;
    text-align: center;
  }

  .center-column {
    display: flex;
    flex: inherit;
    flex-direction: row;
    justify-content: center;
    align-items: center;

  .btn-default {
      display: inline-block;
      min-width: 100%;
      margin-bottom: 1rem;
      font-weight: bold;

      white-space: nowrap;
      vertical-align: middle;
      touch-action: manipulation;
      cursor: pointer;

      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      background-image: none;
      padding: 15px 25px;
      background: linear-gradient(
        90deg,
        rgb(239, 87, 97) 0%,
        rgb(239, 87, 97) 50%,
        rgba(239, 87, 97, 0.85) 50%,
        rgba(239, 87, 97, 0.85) 100%
      );
      background-size: 200% 100%;
      background-position: right;
      color: #280004;
      transition: background 1s ease;
      border-radius: 5px;
      text-transform: uppercase;
      line-height: 1.3;
      letter-spacing: 1.25px;
      text-align: center;
      outline: center;

      text-decoration: none;
      font-family: "FF Clan Pro", sans-serif;
    }

.btn-default:hover {
  background-position: left;
}
  }
</style>
