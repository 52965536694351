import { render, staticRenderFns } from "./Unifesp.vue?vue&type=template&id=55979339&scoped=true"
import script from "./Unifesp.vue?vue&type=script&lang=js"
export * from "./Unifesp.vue?vue&type=script&lang=js"
import style0 from "./Unifesp.vue?vue&type=style&index=0&id=55979339&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55979339",
  null
  
)

export default component.exports