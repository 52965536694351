import Vue from "vue";
import moment from "moment";
moment.locale("pt-Br");

Vue.filter("formatShortDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD-MM-YYYY");
  }
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD-MM-YYYY - HH:mm:ss");
  }
});

Vue.filter("formatDatePersonal", function (value) {
  if (value) {
    return moment(String(value)).format("DD-MM");
  }
});

Vue.filter("formatDate-day", function (value) {
  if (value) {
    return moment(String(value)).format("DD");
  }
});
Vue.filter("formatDate-month", function (value) {
  if (value) {
    return moment(String(value)).format("MMMM");
  }
});
Vue.filter("formatDate-year", function (value) {
  if (value) {
    return moment(String(value)).format("YYYY");
  }
});
Vue.filter("formatDecimalNumber", function (value) {
  if (value === null || value === undefined || value === '' || isNaN(parseFloat(value))) return "0,00";

  if (parseFloat(value) === 0) return "-";

  const val = Number(value).toFixed(2).replace(".", ",");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
});
//  if (!value) return '';
  // if (parseFloat(value) === 0) return '-';