<template>
  <div class="main-header">
    <b-row>
      <b-col cols="12">
        <div class="header_top_bar d-none d-md-none d-lg-block d-xl-block">
          <div class="container-fluid first_navbar" order="1">
            <div class="clearfix">
              <div class="pull-right hidden-xs d-lg-block ml-auto">
                <div class="header_group_url" style="padding: 2px 7px 0 0">
                  <a href="https://cpv.com.br"> Grupo CPV </a>
                  <span class="mx-1">|</span>
                  <a href="https://cursinho.cpv.com.br"> Cursinho </a>
                  <span class="mx-1">|</span>
                  <a href="https://colegio.cpv.com.br"> Colégio </a>
                  <span class="mx-1">|</span>
                  <a href="https://www.sistemacpv.com.br"> Sistema CPV </a>
                  <span class="mx-1">|</span>
                  <a href="https://alfred.com.br" target="_blank"> Alfred </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-navbar
          id="mainNav"
          class="color-mobile navbar_principal pt-lg-2 pb-lg-3 pb-sm-0"
          toggleable="lg"
          fixed="top"
          :class="$route.name === 'homecpv' ? 'bg_registro_fade' : 'bg_registro'"
        >
          <b-navbar-brand to="/" class="pl-sm-0 pl-md-0 pl-lg-2">
            <b-img fluid :src="logoSrc" alt="GRUPO CPV" width="110" height="100%" />
          </b-navbar-brand>

          <b-button v-b-toggle:nav-collapse class="button-collapse d-lg-none">
            <i class="fa-solid fa-bars"></i>
            
          </b-button>

          <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->

          <b-collapse id="nav-collapse" is-nav class="mt-4 navbar_collapse">
            <b-navbar-nav class="ml-auto">
              <b-nav-item-dropdown right class="link-primary">
                <template #button-content>
                  <span>O Cursinho</span>
                </template>
                <div class="subitem">
                  <b-dropdown-item
                    href="https://cpv.com.br/grupo-cpv"
                    class="submenu"
                  >
                    <span>Quem Somos</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    :to="{ name: 'exame-bolsa' }"
                    class="submenu"
                    :class="{ active: isActiveMenuItem('exame-bolsa') }"
                  >
                    <span>Exame de Bolsa</span>
                  </b-dropdown-item>
                </div>
                <div class="subitem">
                  <b-dropdown-item
                    :to="{ name: 'calendario-de-vestibulares' }"
                    class="submenu"
                    :class="{ active: isActiveMenuItem('calendario-de-vestibulares') }"
                  >
                    <span>Calendário de Vestibulares</span>
                  </b-dropdown-item>
                </div>
                <div class="subitem">
                  <b-dropdown-item
                    :to="{ name: 'ebook' }"
                    class="submenu"
                    :class="{ active: isActiveMenuItem('ebook') }"
                  >
                    <span>E-Books</span>
                  </b-dropdown-item>
                </div>
                <div class="subitem">
                  <b-dropdown-item
                    :to="{ name: 'simulados-abertos-cpv' }"
                    class="submenu"
                    :class="{ active: isActiveMenuItem('simulados-abertos-cpv') }"
                  >
                    <span>Simulados Abertos CPV</span>
                  </b-dropdown-item>
                </div>
                <div class="subitem">
                  <b-dropdown-item
                    :to="{ name: 'cpv-resolve' }"
                    class="submenu"
                    :class="{ active: isActiveMenuItem('cpv-resolve') }"
                  >
                    <span>Vestibulares Resolvidos</span>
                  </b-dropdown-item>
                </div>
              </b-nav-item-dropdown>
              <b-nav-item
                class="link-primary"
                :to="{ name: 'proximasturmas' }"
                :class="{ active: isActiveMenuItem('proximasturmas') }"
              >
                Próximas Turmas
                <div class="magic-line line-visible"></div>
              </b-nav-item>

              <b-nav-item-dropdown right class="link-primary">
                <template #button-content>
                  <span>Área do Aluno</span>
                </template>
                <div class="subitem">
                  <b-dropdown-item href="https://alfred.com.br/" target="_blank" class="submenu">
                    <span>Alfred - Inteligência Educacional</span>
                  </b-dropdown-item>
                </div>
                <div class="subitem">
                  <b-dropdown-item href="https://classapp.com.br/" target="_blank" class="submenu">
                    <span>Class App - Comunicação</span>
                  </b-dropdown-item>
                </div>
                <div class="subitem">
                  <b-dropdown-item href="https://classroom.google.com/h" target="_blank" class="submenu">
                    <span>ClassRoom - Conteúdos</span>
                  </b-dropdown-item>
                </div>
              </b-nav-item-dropdown>
              <b-nav-item href="https://cpv.com.br/contato" right class="link-primary">
                <span>Contato</span>
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </b-col>
    </b-row>
    <!-- <whatsapp /> -->
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
// import whatsapp from "./pages/fabWhattsApp.txt";

export default {
  name: "Header",
  components: {
    // whatsapp,
  },

  data() {
    return {
      menu: [],
    };
  },

  created() {
    // this.getMenu();
  },
  mounted() {
    this.onScrollEvent();
  },
  computed: {
    section: function () {
      return this.$router.currentRoute.meta.section_name;
    },

    isSmallerDevice() {
      return window.innerWidth <= 992;
    },
    headerType: function () {
      switch (this.$route.meta.section_name) {
        case "grupo":
          return "header-grupo";
          break;
        case "cursinho":
          return "header-cursinho";
          break;
        case "colegio":
          return "header-colegio";
          break;
        default:
          return "header-grupo";
          break;
      }
    },
    logoSrc: function () {
      switch (this.$route.meta.section_name) {
        case "grupo":
          return "https://mysociety.s3.amazonaws.com/logoCPV/logo-grupo.png";
          break;
        case "cursinho":
          return "https://mysociety.s3.amazonaws.com/logoCPV/new-logo-cpv.png";
          break;
        case "colegio":
          return "https://mysociety.s3.amazonaws.com/logoCPV/logo-colegio.png";
          break;
        default:
          return "https://mysociety.s3.amazonaws.com/logoCPV/cpv_educacional_positivo_sem_grafismo.png";
          break;
      }
    },
  },
  watch: {},
  methods: {
    getMenu: function () {
      ApiService.get(`sitecpv/getMenu/${this.headerType}`)
        .then((response) => {
          this.menu = response.data.menu;
        })
        .catch((errors) => {
          console.log(errors);
        })
        .finally(() => {});
    },

    mountedMenu() {
      if (this.section === "grupo") {
        console.log(this.section);
      }

      if (this.section === "cursinho") {
        console.log(this.section);
      }
    },
    menuToggleLink(id) {
      if (document.getElementById(id).classList.contains("open")) {
        document.getElementById(id).classList.remove("open");
      } else if (!document.getElementById(id).classList.contains("open")) {
        let elements = document.querySelectorAll(".parent");
        for (var i = 0; i < elements.length; i++) {
          elements[i].classList.remove("open");
        }
        document.getElementById(id).classList.add("open");
      }
    },
    addToggleClass() {
      document.querySelector("body").classList.toggle("rtl-enable");
    },
    removeCollapseInClass() {
      document.getElementById("nav-collapse").classList.remove("show");
    },
    onScrollEvent() {
      var headerSticky = document.getElementById("mainNav");
      window.onscroll = function () {
        if (window.pageYOffset >= 10) {
          headerSticky.classList.add("scrollHeader");
        } else {
          headerSticky.classList.remove("scrollHeader");
        }
      };
    },
    isActiveMenuItem(routeName) {
      return this.$route.name === routeName;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-header {
  font-family: "FF Clan Pro", sans-serif;
  font-weight: 700;
  .header_top_bar {
    width: 100%;
    font-weight: 600;
    z-index: 1099;
    position: fixed;

    color: #f6f6f6;
    .pull-right {
      float: right;
      display: block;
    }
    .first_navbar {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    a {
      color: #f6f6f6;
      font-weight: 600;
      font-style: normal;
      font-size: 11.5px;

      text-transform: uppercase;
      letter-spacing: 0.01em;

      &:hover {
        color: rgb(239, 72, 87);
        text-decoration: none;
      }
    }
  }

  .navbar_principal {
    background-color: rgb(58, 28, 83);
    padding: 0px 15px;
    .active {
      font-size: 15px;
      a {
        margin-top: 0px;
        color: rgb(239, 72, 87) !important;
        font-size: 15px !important;
        letter-spacing: 0.01em;
        transition: all 0.2s ease-in-out;
      }
    }
    .link-primary {
      a {
        color: #f6f6f6;
        text-transform: uppercase;
        letter-spacing: 0.01em;
        font-size: 15px;
      }

      .dropdown-toggle {
        color: #f6f6f6 !important;
        span {
          color: #f6f6f6;
          letter-spacing: 0.01em;
          text-transform: uppercase;
        }
      }
    }

    .subitem .active {
      background-color: #fff;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .main-header {
    .button-collapse {
      background-color: transparent;
      border: 1px solid #ffffff;
      &:hover {
        background-color: transparent;
        box-shadow: transparent;
        border: 1px solid #ffffff;
      }
      &:focus {
        background-color: transparent;
        border: transparent;
        box-shadow: transparent;
        outline: none;
      }

      &:active {
        background-color: transparent;
        border: transparent;
        box-shadow: transparent;
        outline: none;
      }
    }

    .navbar_principal {
      .subitem .active {
        background-color: transparent;
        .dropdown-item span {
          color: rgb(239, 72, 87) !important;
        }
      }
    }

    .navbar_collapse {
      padding-bottom: 20px;
    }
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .main-header {
    .button-collapse {
      background-color: transparent;
      border: 1px solid #ffffff;
      &:hover {
        background-color: transparent;
        box-shadow: transparent;
        border: 1px solid #ffffff;
      }
      &:focus {
        background-color: transparent;
        border: transparent;
        box-shadow: transparent;
        outline: none;
      }

      &:active {
        background-color: transparent;
        border: transparent;
        box-shadow: transparent;
        outline: none;
      }
    }
    .navbar_principal {
      .subitem .active {
        background-color: rgb(58, 28, 83) !important;
        .dropdown-item span {
          color: rgb(239, 72, 87) !important;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .main-header {
    .button-collapse {
      background-color: transparent;
      border: 1px solid #f6f6f6;
      &:hover {
        background-color: transparent;
        box-shadow: transparent;
        border: 1px solid #f6f6f6;
      }
      &:focus {
        background-color: transparent;
        border: transparent;
        box-shadow: transparent;
        outline: none;
      }

      &:active {
        background-color: transparent;
        border: transparent;
        box-shadow: transparent;
        outline: none;
      }
    }
    .navbar_principal {
      .subitem .active {
        background-color: rgb(58, 28, 83) !important;
        .dropdown-item span {
          color: rgb(239, 72, 87) !important;
        }
      }
    }
  }
}
</style>
