<template>
  <div class="d-block w-100 fade-in-up">
    <div
      class="jumbotron jumbotron-fluid bg-primary mb-5 title-page"
      style="
        margin-top: 95px !important;
        padding-top: 80px !important;
        padding-bottom: 28px !important;
        background-color: #f16f31 !important;
        display: flex;
        justify-content: center;
        text-align: center;
      "
    >
      <h1 class="text-uppercase pt-0 pb-0 mt-3">Moradias</h1>
    </div>
    <b-breadcrumb style="margin-top: -48px; background-color: #f6f6f6; color: #aaaaaa">
      <b-container>
        <b-breadcrumb-item href="/">
          <strong>Cursinho CPV</strong>
        </b-breadcrumb-item>
        <b-breadcrumb-item class="text-uppercase" active> Moradias </b-breadcrumb-item>
      </b-container>
    </b-breadcrumb>

    <section class="container-moradias">
      <div class="row mt-0">
        <div class="col-md-3 col-lg-3 bg-none pt-5 pb-5 pl-4 pr-4">
          <nav id="scrollspy">
            <!-- Pesquisar -->
            <li class="nav-item" style="list-style-type: none">
              <label class="box-info pl-3 pr-3"><i class="fa-light fa-magnifying-glass mr-1"></i> Pesquisar:</label>
              <hr class="box-info-hr" />
            </li>
            <ul class="nav flex-column">
              <li class="nav-item">
                <form>
                  <div class="form-group">
                    <input type="search" class="form-control" id="" placeholder="Digite a sua busca:" />
                  </div>
                </form>
              </li>
            </ul>

            <ul class="nav w-100 justify-content-end">
              <li class="nav-item w-50">
                <a href="#" class="btn btn-outline-primary btn-sm w-100 btn-search">Pesquisar</a>
              </li>
            </ul>

            <!-- Endereços -->
            <li class="nav-item mt-4" style="list-style-type: none">
              <label class="box-info pl-3 pr-3"><i class="fa-light fa-location-dot mr-1"></i> Região:</label>
              <hr class="box-info-hr" />
            </li>
            <ul class="nav flex-column" v-for="endereco in enderecos" :key="endereco.id">
              <li class="nav-item mb-0">
                <span class="nav-link btn_filter" @click="filter = endereco.id">{{ endereco.desc }}</span>
              </li>
            </ul>
            <!-- Moradias -->
            <li class="nav-item mt-4" style="list-style-type: none">
              <label class="box-info pl-3 pr-3"><i class="fa-light fa-apartment mr-1"></i> Imóvel:</label>
              <hr class="box-info-hr" />
            </li>
            <ul class="nav flex-column" v-for="moradia in moradias" :key="moradia.id">
              <li class="nav-item">
                <span class="nav-link btn_filter" @click="filter_home = moradia.id">{{ moradia.desc }}</span>
              </li>
            </ul>
          </nav>
        </div>

        <div class="col-12 col-sm-12 col-md-9 col-lg-9 mt-4 mb-0">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-4 col-lg-4" v-for="lists in list" :key="lists.id">
              <div
                class="card-group shadow-sm mb-5"
                style="width: 20rem; height: 19rem"
                v-if="0 === filter || 0 === filter_home"
              >
                <div class="card card_layout">
                  <div class="card-header content_header bg_header">
                    <h5 class="card-title">{{ lists.title }}</h5>
                  </div>
                  <div class="card-body content_card">
                    <p class="card_text"><i class="bi bi-geo-alt mr-1 card_icon"></i>{{ lists.address }}</p>
                    <p class="card_text"><i class="bi bi-telephone mr-1 card_icon"></i>{{ lists.phone }}</p>
                    <p class="card_text" :class="lists.display_none">
                      <i class="bi bi-envelope mr-1 card_icon" :class="lists.display_none"></i>{{ lists.email }}
                    </p>
                    <br />
                    <a :href="[`${lists.urlLink}`]" :class="lists.display_link">
                      <i class="bi bi-globe2 mr-1 card_icon"></i><span class="card_text">{{ lists.website }}</span>
                    </a>
                  </div>
                  <div class="card-footer bg_footer card_footer">
                    <p class="card_text">&#9400; CPV - Todos os direitos reservados</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-sm-12 col-md-4 col-lg-4" v-for="lists in list" :key="lists.id">
              <div
                class="card-group card-moradias shadow-sm"
                style="width: 20rem; height: 22rem"
                v-if="lists.filter === filter && lists.filter_home === filter_home"
              >
                <div class="card card_layout">
                  <div class="card-header content_header bg_header">
                    <h5 class="card-title">{{ lists.title }}</h5>
                  </div>
                  <div class="card-body content_card">
                    <p class="card_text"><i class="bi bi-geo-alt mr-1 card_icon"></i>{{ lists.address }}</p>
                    <p class="card_text"><i class="bi bi-telephone mr-1 card_icon"></i>{{ lists.phone }}</p>
                    <p class="card_text" :class="lists.display_none">
                      <i class="bi bi-envelope mr-1 card_icon" :class="lists.display_none"></i>{{ lists.email }}
                    </p>
                    <br /><br />
                    <a :href="[`${lists.urlLink}`]" :class="lists.display_link">
                      <i class="bi bi-globe2 mr-1 card_icon"></i><span class="card_text">{{ lists.website }}</span>
                    </a>
                  </div>
                  <div class="card-footer bg_footer card_footer">
                    <p class="card_text">&#9400; CPV - Todos os direitos reservados</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

.fade-in-up {
  -webkit-animation: fadeIn 0.4s ease-in-out;
  -moz-animation: fadeIn 0.4s ease-in-out;
  -o-animation: fadeIn 0.4s ease-in-out;
  animation: fadeIn 0.4s ease-in-out;
}
.main-jumbotron {
  width: 100%;
  height: auto;
  margin-bottom: 0px;
}

.box-info {
  color: #fff;
  background-color: #f16f30;
  padding: 0.3rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 4px 0px 4px rgb(235, 235, 235);
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
}

.box-info-hr {
  margin-top: -0.5rem;
}

.btn-search {
  font-size: 0.9rem;
  font-weight: 400;
  border: 1px solid#f16f30 !important;
  background-color: #f16f30 !important;
  color: #fff !important;
}

.btn-search:hover {
  background-color: #f16f30 !important;
  border-color: #f15b11 !important;
  transition: all 0.7s ease-in-out;
  box-shadow: 4px 4px 10px #ccc !important;
}

.btn_filter {
  text-transform: uppercase;
  color: #f16f30;
  font-weight: 500;
  font-size: 0.85rem;
}

.btn_filter:hover {
  padding: 10px 10%;
  background-color: #f16f30;
  color: #fff;
  transition: 0.5s;
  width: 75% !important;
  border-radius: 5px;
  box-shadow: 4px 4px 10px #ccc;
}

.card-moradias {
  border-radius: 25px !important;
}

.card_layout {
  border: 1px solid #f16f30 !important;
  border-radius: 8px !important;
}

.bg_header {
  background-color: #f16f30 !important;
  box-shadow: 0px 4px 6px rgba(204, 204, 204, 0.5);
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.bg_footer {
  background-color: #f16f30 !important;
}

.content_header h5 {
  color: #fff;
  font-size: 0.8rem !important;
  font-weight: 600;
  padding: 10px 0;
  margin-bottom: 0;
  letter-spacing: 0.005rem;
  text-transform: uppercase;
}

.content_card {
  padding: 15px 10px;
}

.content_card .card_text {
  font-size: 0.8rem;
}

.content_card .card_icon,
a {
  color: #f04b5d;
  text-transform: uppercase;
  font-weight: 500;
}

.information_none,
.link_none {
  display: none;
  padding-top: -100px !important;
}

.bg_footer {
  background: #f04b5d;
  box-shadow: 0px -4px 12px rgba(204, 204, 204, 0.5);
  padding: 10px 0 0;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.card_footer p {
  color: #fff;
  padding: 0px 8px;
  font-size: 0.8rem;
}
</style>

<script>
export default {
  name: "moradia",

  data() {
    return {
      list: [
        {
          id: 0,
          title: "F.L Residence (Vila Olímpia)",
          address: "Rua Elvira Ferraz, 250 – Vila Olímpia",
          phone: "(11) 3246-5450 / 0800 773 6378",
          website: "F.L Residence",
          icon_maps: "bi bi-geo-alt",
          urlLink: "https://www.flresidence.com.br/",
          filter: 2,
          filter_home: 1,
          display_none: "information_none",
        },
        {
          id: 1,
          title: "Olympia Residence",
          address: "Rua Gomes de Carvalho, 850 – Vila Olímpia",
          phone: "(11) 5186-3600",
          website: "Olympia Residence",
          email: "reservas@olympiaresidence.com.br",
          urlLink: "https://olympia-residence.allsaopaulohotels.com/pt/",
          filter: 2,
          filter_home: 1,
        },
        {
          id: 2,
          title: "Apartamentos Mobiliados Studio",
          address: "a um quarteirão do CPV – Consolação",
          phone: "(11) 97161-2469 (Whatsapp)",
          website: "-",
          email: "-",
          filter: 1,
          filter_home: 1,
          display_none: "information_none",
          display_link: "link_none",
        },

        {
          id: 3,
          title: "Fernandes Mera",
          address: "Av. Dr. Guilherme Dumont Vilares, 2476",
          phone: "(11) 3058-8787",
          website: "-",
          email: "-",
          filter: 3,
          filter_home: 3,
          display_none: "information_none",
          display_link: "link_none",
        },
        {
          id: 4,
          title: "Imóveis no Morumbi",
          address: "R. Mal. Hastimphilo de Moura, 233 – Loja 04",
          phone: "(11) 3740-5555",
          website: "-",
          email: "-",
          filter: 3,
          filter_home: 3,
          display_none: "information_none",
          display_link: "link_none",
        },
        {
          id: 5,
          title: "Imobiliaria Morumbi Sul",
          address: "Av. Dr. Guilherme Dumont Vilares, 560",
          phone: "(11) 2539-3301",
          website: "-",
          email: "-",
          filter: 3,
          filter_home: 3,
          display_none: "information_none",
          display_link: "link_none",
        },
        /* {
                     id: 6,
                     title: 'C.A. Imóveis',
                     address: 'Rua João Cachoeira, 1364 – Vila Olímpia',
                     phone: '(11) 3845-3255 / (11) 98356-5077',
                     website: '-',
                     email: '-',
                     filter: 2,
                     filter_home: 3,
                     display_none: 'display_none',
                     display_link: 'display_none',
                 },
                 {
                     id: 7,
                     title: 'Estanconfor (Itaim)',
                     address: 'Rua João Cachoeira, 292 – Itaim',
                     phone: '(11) 3704-6000',
                     website: 'Estanconfor',
                     urlLink: 'https://estanconfor.com.br/',
                     email: 'itaim@estanconfor.com.br',
                     filter: 2,
                     filter_home: 2
                 },
                 {
                     id: 8,
                     title: 'Meliá Ibirapuera',
                     address: 'Av. Ibirapuera, 2534 – Moema',
                     phone: '(11) 3509-8701',
                     website: 'Meliá Ibirapuera',
                     email: 'reservas2.ibirapuera@meliahotels.com.br',
                     urlLink: 'https://www.melia.com/',
                     filter: 2,
                     filter_home: 2
                 },
                 {
                     id: 9,
                     title: 'Hotel Radisson',
                     address: 'Rua Fidêncio Ramos, 420 – Itaim',
                     phone: '(11) 4395-3279',
                     website: 'Hotel Radisson',
                     email: 'reservas.rvo@atlanticahotels.com.br',
                     urlLink: 'www.atlanticahotels.com.br/hotel/sao-paulo/radisson-vila-olimpia',
                     filter: 2,
                     filter_home: 1
                 },
                 {
                     id: 10,
                     title: '4Student',
                     website: '4Student',
                     email: 'contato@4student.com.br',
                     urlLink: 'https://www.4student.com.br/',
                     filter: 1,
                     filter_home: 1
                 }, */
        /* 		{

id: 11,

title: 'JSI - Jardim Sul',

address: 'R. Nelson Gama de Oliveira, 120',

phone: '(11) 3773-3303',

website: '-',

email: '-',

filter: 4,

filter_home: 4

},

{

id: 12,

title: 'CENTURY 21 MORUMBI GOLD',

address: 'Rua Dr. Luiz Migliano, 340',

phone: '(11) 3739-2799',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 13,

title: 'Sucefi imóveis',

address: 'R. Sebastião Francisco, 3480',

phone: '(11) 3742-1533',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 14,

title: 'PLUS IMÓVEIS - AGÊNCIA PANAMBY',

address: 'R. Dep. João Sussumu Hirata, 539',

phone: '(11) 3022-6000',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 15,

title: 'Coelho da Fonseca Morumbi',

address: 'Av. Giovanni Gronchi, 4020',

phone: '(11) 3745-6000',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 16,

title: 'Lincoln Yuji Imóveis',

address: 'Av. Giovanni Gronchi, 6195 – sala 1412',

phone: '(11) 96013-7288',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 17,

title: 'Olimpo Imobiliaria no Morumbi',

address: 'R. Nazira Carone, 9',

phone: '(11) 3739-0333',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 18,

title: 'Imobiliária Objetivo',

address: 'Av. Dr. Guilherme Dumont Vilares, 566',

phone: '(11) 3507-1000',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 19,

title: 'AIMBI Imóveis (Morumbi)',

address: 'R. José Ramon Urtiza, 890',

phone: '(11) 3507-3836',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 20,

title: 'Minha Casa Imobiliária',

address: 'Av. Dr. Guilherme Dumont Vilares, 732',

phone: '(11) 3739-2528',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 21,

title: 'm² Consultoria Imobiliária',

address: 'R. Adalívia de Tolêdo, 177',

phone: '(11) 3758-7000',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 22,

title: 'Imobiliária Morumbi Haus',

address: 'R. Nelson Gama de Oliveira, 311',

phone: '(11) 2592-9800',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 23,

title: 'AMEL Imoveis',

address: 'Rua Dr. Luiz Migliano, 1110',

phone: '(11) 3507-0707',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 24,

title: 'Lavieri',

address: 'R. Min. Heitor Bastos Tigre, 81',

phone: '(11) 3744-5500',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 25,

title: 'Fanel Imóveis',

address: 'Rua Regente León Kaniefsky, 113',

phone: '(11) 3744-5000',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 26,

title: 'Guedala Imóveis',

address: 'R. Dom Armando Lombardi, 462',

phone: '(11) 3723-4050',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 27,

title: 'Imobiliária Paulo Roberto Leardi',

address: 'Av. Giovanni Gronchi, 2287',

phone: '(11) 3740-4000',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 28,

title: 'Morumbi mix',

address: 'Rua Dr. Luiz Migliano, 928',

phone: '(11) 3805-1111',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 29,

title: 'PLUS IMÓVEIS',

address: 'Rua Regente León Kaniefsky, 512',

phone: '(11) 5055-9900',

website: '-',

email: '-',

filter: 2,

filter_home: 3

},

{

id: 30,

title: 'Dinâmica Gestão Imobiliária',

address: 'R. André Saraíva, 18',

phone: '(11) 3772-0055',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 31,

title: 'Imoveis Morumbi',

address: 'Av. Morumbi, 6045',

phone: '(11) 3755-9999',

website: '-',

email: '-',

filter: 3,

filter_home: 3

},

{

id: 32,

title: 'Ricofer Imóveis',

address: 'Rua Ribeirão Claro, 572 – Vila Olímpia',

phone: '(11) 2157-5165',

website: 'Ricofer Imóveis',

email: 'ricoferimoveis@gmail.com',

urlLink: 'www.ricoferimoveis.com.br',

filter: 2,

filter_home: 3

},

{

id: 33,

title: 'Prive',

address: 'Rua Ponta Delgada, 76 – Itaim Bibi',

phone: '(11) 3849-3211',

website: 'Prive Cidade Jardim',

email: 'Sra. Rosa',

urlLink: 'privecidadejardim.com.br',

filter: 2,

filter_home: 2

},

{

id: 34,

title: 'Double Tree by Hilton Itaim',

address: 'R. Manuel Guedes, 320 – Itaim',

phone: '(11) 3066-2699',

website: 'Double Tree By Hilton Itaim',

email: 'vendas.dtit@atlanticahotels.com.br',

urlLink:

'https://www.hiltonhotels.com/pt_BR/brasil/doubletree-by-hilton-sao-paulo-itaim/',

filter: 2,

filter_home: 2

},

{

id: 35,

title: 'Pulman e Gran Mercury Vila Olímpia',

address: 'Rua Olimpíadas, 205 – Vila Olímpia',

phone: '(11) 3049-6622 / 2161-4900',

website: 'Pulman e Gran Mercury Vila Olímpia',

email: 'h8938-re@acor.com.br',

urlLink: 'https://www.all.accor.com/',

filter: 2,

filter_home: 2

},

{

id: 36,

title: 'Estanplaza (Berrini)',

address: 'Av. Eng. Luis Carlos Berrini, 853 – Itaim',

phone: '(11) 5509-8900',

website: 'Estanplaza Berrini',

email: 'reservas@estanplaza.com.br',

urlLink: 'www.estanplaza.com.br',

filter: 2,

filter_home: 2

},

{

id: 37,

title: 'Estanplaza (Vila Olímpia)',

address: 'Rua Funchal, 281 – Vila Funchal',

phone: '(11) 3059-3277',

website: 'Estanplaza',

email: 'funchal@estanplaza.com.br',

urlLink: 'www.estanplaza.com.br',

filter: 2,

filter_home: 2

},

{

id: 38,

title: 'Confort (Moema)',

address: 'Av. Sabiá, 825 – Moema',

phone: '(11) 2197-7200',

website: 'Confort Moema',

email: 'reservas.coib@atlanticahotels.com.br',

urlLink: 'www.atlanticahotels.com.br',

filter: 2,

filter_home: 2

},

{

id: 39,

title: 'Blue Tree Premium (Faria Lima)',

address: 'Av. Faria Lima, 3989 – Vila Olímpia',

phone: '(11) 3896-7544 / (11) 3896-7545 - Fax',

website: 'Blue Tree Premium',

email: 'reservas1.farialima@bluetree.com.br',

urlLink: 'www.bluetree.com.br',

filter: 2,

filter_home: 2

},

{

id: 40,

title: 'Mercure J.K',

address: 'Av. Funchal, 111 – Vila Funchal',

phone: '(11) 3046-3800 / (11) 3046-3900',

website: 'Mercure J.K',

email: 'h2128-re@accor.com.br',

urlLink: 'www.all.accor.com',

filter: 2,

filter_home: 1

},

{

id: 41,

title: 'Mercure Vila Olímpia',

address: 'Rua Sta Justina, 210 – Vila Olímpia',

phone: '(11) 3089-6222',

website: 'Mercure Vila Olímpia',

email: 'h3206-re@accor.com.br',

urlLink: 'www.mercure.com.br',

filter: 2,

filter_home: 1

},

{

id: 42,

title: 'Flat Prive Cidade Jardim (Vila Olímpia)',

address: 'Rua Ponte Delgada, 76 – Vila Olímpia',

phone: '(11) 3849-3211 / (11) 3849-3211 - Fax',

website: 'Prive Cidade Jardim',

email: '-',

urlLink: 'www.privecidadejardim.com.br',

filter: 2,

filter_home: 1

},

{

id: 43,

title: 'Pensão Maria Imaculada (F)',

address: 'Al. Itu, 920 – Cerqueira Cesar',

phone: '(11) 3061-2877',

website: '-',

email: '-',

filter: 1,

filter_home: 4

},

{

id: 44,

title: 'Morada do Estudante (M)',

address: 'Rua Teixeira Mendes, 87 – Liberdade',

phone: '(11) 3277-3061 / (11) 99707-2181 c/ Gleci',

website: '-',

email: 'moradadoestudante@pop.com.br',

filter: 1,

filter_home: 4

},

{

id: 45,

title: 'Quality Suítes Long Stay',

address: 'Rua Bela Cintra, 521 – Consolação',

phone: '(11) 3217-9000',

website: '-',

email: '-',

filter: 1,

filter_home: 2

},

{

id: 46,

title: 'The Laundmark Residence Hotel',

address: 'Al. Jaú, 1607 – Cerqueira Cesar',

phone: '(11) 3082-8677',

website: '-',

email: '-',

filter: 1,

filter_home: 1

},

{

id: 47,

title: 'Pensionato Masculino (M)',

address: 'Rua Dr Seng, 64 (Prox. a Al. Campinas) – Bela Vista',

phone: '(11) 3284-2028 c/ Sr. Argentina',

website: '-',

email: '-',

filter: 1,

filter_home: 4

},

{

id: 48,

title: 'Riema Mont Hermont Flat',

address: 'Rua Fernando de Albuquerque, 86 – Consolação',

phone: '(11) 3256-8311/ 3256-8311',

website: '-',

email: '-',

filter: 1,

filter_home: 1

},

{

id: 49,

title: 'Elysee Plaza Flat Service',

address: 'Rua Marques de Paranaguá, 33 – Consolação',

phone: '(11) 3258-6888',

website: '-',

email: '-',

filter: 1,

filter_home: 1

},

{

id: 50,

title: 'Flat Massis Stars',

address: 'Rua Luis Coelho, 80 – Consolação',

phone: '(11) 3141-4400',

website: '-',

email: '-',

filter: 1,

filter_home: 1

},

{

id: 51,

title: 'The World Hotels',

address: 'Rua Gomes de Carvalho, 1005 – Vila Olímpia',

phone: '(11) 3049-6700',

website: 'The World Hotels',

email: '-',

urlLink: 'www.transamerica.com.br',

filter: 1,

filter_home: 2

},

{

id: 52,

title: 'Bela Cintra Flat Service',

address: 'Rua Bela Cintra, 196 – Cerqueira Cesar',

phone: '(11) 2832-8700',

website: '-',

email: '-',

filter: 1,

filter_home: 1

},

{

id: 53,

title: 'Moradia para Estudantes "VIVER BEM" - CONSOLAÇÃO (F) e (M)',

address: 'Rua da Consolação, 867 Apto. 82 (fem.) e Apto. 112 (masc.) – Consolação',

phone: '(11) 3675-7936 / 3865-4394 / (11) 99609-9600 c/ Sergio',

website: 'Moradia Viver Bem',

email: 'sergio@moradiaviverbem.com',

urlLink: 'www.moradiaviverbem.com',

filter: 1,

filter_home: 4

},

{

id: 54,

title: 'Hotel San Gabriel',

address: 'Rua Frei Caneca, 1006 – Consolação',

phone: '(11) 3253-2279',

filter: 1,

filter_home: 2

},

{

id: 55,

title: 'Flat Transamérica',

address: 'Al. Lorena, 1748 – Cerqueira Cesar',

phone: '(11) 3062-2666',

filter: 1,

filter_home: 1

},

{

id: 56,

title: 'Quality Suítes Long Stay (Itaim)',

address: 'R. Alvorada, 1009 – Itaim',

email: 'reservas.qsvo@atlanticahotels.com.br',

phone: '(11) 3062-2666',

filter: 1,

filter_home: 2

},

{

id: 57,

title: 'THE WORLD HOTELS',

address: 'Rua Gomes de Carvalho, 1005 – Vila Olímpia',

email: 'reservas.qsvo@atlanticahotels.com.br',

phone: '(11) 3049-6700',

filter: 1,

filter_home: 1

},

{

id: 58,

title: 'JSI - Jardim Sul ',

address: 'R. Nelson Gama de Oliveira, 120',

phone: '(11) 3773-3303',

filter: 3,

filter_home: 3

},

{

id: 59,

title: 'JSI - Jardim Sul ',

address: 'R. José Ramon Urtiza, 890',

phone: '(11) 3507-3836',

filter: 3,

filter_home: 3

},

{

id: 60,

title: 'Meliá Ibirapuera ',

address: 'Av. Ibirapuera, 2534 – Moema',

phone: '(11) 3509-8701',

email: 'reservas2.ibirapuera@meliahotels.com.br',

urlLink: 'https://www.melia.com/',

filter: 1,

filter_home: 1

},

{

id: 61,

title: 'DOUBLE TREE BY HILTON ITAIM',

address: 'R. Manuel Guedes, 320 – Itaim',

phone: '(11) 3066-2699',

email: 'vendas.dtit@atlanticahotels.com.br',

urlLink:

'https://www.hiltonhotels.com/pt_BR/brasil/doubletree-by-hilton-sao-paulo-itaim/',

filter: 1,

filter_home: 2

},

{

id: 62,

title: 'PULLMAN E GRAN MERCURY VILA OLÍMPIA',

address: 'Rua Olimpíadas, 205 – Vila Olímpia',

phone: '(11) 3049-6622 / 2161-4900',

email: 'h8938-re@acor.com.br',

urlLink: 'https://www.all.accor.com/',

filter: 1,

filter_home: 2

} */
      ],
      enderecos: [
        {
          id: 0,
          desc: "Todos",
        },
        {
          id: 1,
          desc: "Consolaçâo",
        },
        {
          id: 2,
          desc: "Morumbi",
        },
        {
          id: 3,
          desc: "Vila Olímpia",
        },
      ],
      moradias: [
        {
          id: 0,
          desc: "TODOS",
        },
        {
          id: 1,
          desc: "FLAT",
        },
        {
          id: 2,
          desc: "HOTEL",
        },
        {
          id: 3,
          desc: "IMOBILÍRIA",
        },
        {
          id: 4,
          desc: "PENSIONATO",
        },
      ],

      // variavel responsavel por receber a opcao clicada no btn
      filter: 0,
      filter_home: 0,
    };
  },
  methods: {
    regiao(id) {},
  },
};
</script>

<style scoped>
.title-page {
  margin-top: 5rem !important;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px !important;

  background-image: url("@/assets/images/cpv-fundo-4.webp") !important;
}
.container-moradias {
  width: 80%;
  display: block;
  margin: 0px auto;
}
</style>
