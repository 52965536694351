<template>
  <div>
    <div class="line"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.line::before {
  content: '';
  width: 88%;
  height: 2px;
  position: absolute;
  background: #dcdcdc;
  left: 10%;
}
.line::after {
  content: '';
  width: 7%;
  height: 2px;
  position: absolute;
  background: rgb(239,72,87);
}

@media (min-width: 320px) and (max-width: 576px) {
  .line::before {
    content: '';
    width: 82%;
    height: 2px;
    position: absolute;
    background: #dcdcdc;
    left: 15%;
  }
  .line::after {
    content: '';
    width: 9%;
    height: 2px;
    position: absolute;
    background: rgb(239,72,87);
  }
}
</style>
