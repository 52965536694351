<template>
  <div class="main-ebooks mb-5 mb-md-5">
    <b-jumbotron class="title-page mb-5 mt-5 title_page fade-in-up">
      <h1 class="text-center font-weight-bold">E-books</h1>
    </b-jumbotron>
    <bread-cpv class="adjustment-breadcrumb"></bread-cpv>

    <b-container>
      <div class="wrapper">
        <div class="box mb-md-5 mb-lg-0" v-for="item in items" :key="item.id">
          <img :src="item.img" :alt="item.name" />
          <div class="info__book">
            <div class="mt-2 float-left title">
              <span class="font-weight-bolder text-black-100" :class="item.text_color">{{ item.name }}</span>
            </div>
            <div class="float-left text">
              <span class="text-black-50">{{ item.text }}</span>
            </div>
            <div class="mt-3 float-right">
              <b-link :href="item.urlDownload" class="btn__download" :class="item.btn__default"
                >Clique para baixar</b-link
              >
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import BreadCpv from "@/components/global/bread-cpv";
export default {
  name: "ebooksVestibulares",
  metaInfo: { title: "E-Books" },
  components: { BreadCpv },
  data() {
    return {
      echoTimeout: null,
      items: [
        /*         {
          id: 1,
          altImg: "Vestibular ADM",
          img: require("@/assets/image/ebooks-vestibulares/fgv-adm-2022-2-1-capa.png"),
          name: "FGV Adm 2022 | 2º Semestre",
          text: "Saiba tudo sobre o Vestibular de 2º Semestre para a FGV Adm",
          nameButton: "Baixar e-book",
          urlDownload: "https://dicas.cpv.com.br/e-book-fgv-adm-2022-2",
        }, */
        {
          id: 2,
          altImg: "Vestibular Medicina Einstein",
          img: require("@/components/global/image/ebooks-vestibulares/newImageBook/Vestibular_Einstein-2025.png"),
          name: "Einstein 2025",
          text: "Saiba tudo sobre o Vestibular 2025 para a Medicina Einstein.",
          nameButton: "Baixar e-book",
          urlDownload: "https://dicas.cpv.com.br/e-book-einstein-2025",
          btn__default: "btn__einstein",
          text_color: "text__einstein",
        },
        // {
        //   id: 3,
        //   altImg: "Vestibular Santa Casa 2022/1",
        //   img: require("@/assets/image/ebooks-vestibulares/CPV-E-book-Santa-Casa-2022-398x269_V8.png"),
        //   name: "Santa Casa 2022 | 1º semestre",
        //   text: "Saiba tudo sobre o Vestibular 2022 para a Medicina da Santa Casa",
        //   nameButton: "Baixar e-book",
        //   urlDownload: "https://dicas.cpv.com.br/e-book-santa-casa-2022",
        // },
        // {
        //   id: 4,
        //   altImg: "Vestibular Insper 2022/1",
        //   img: require("@/assets/image/ebooks-vestibulares/ebook-insper-2022-1p.png"),
        //   name: "Insper 2022 | 1º semestre",
        //   text: "Saiba tudo sobre o Vestibular 2022 da Insper",
        //   nameButton: "Baixar e-book",
        //   urlDownload: "https://dicas.cpv.com.br/e-book-insper-2022-1",
        // },
        {
          id: 5,
          altImg: "Tabela de Física",
          img: require("@/components/global/image/ebooks-vestibulares/newImageBook/Formulas_de_Fisica-1.png"),
          name: "Tabela de Física",
          text: "A ajuda que você precisa na hora de estudar Física.",
          nameButton: "Baixar e-book",
          urlDownload: "https://dicas.cpv.com.br/formulas-de-fisica",
          btn__default: "btn__fisica",
          text_color: "text__fisica",
        },
        {
          id: 6,
          altImg: "Vestibular Insper 2022/1",
          img: require("@/components/global/image/ebooks-vestibulares/newImageBook/Formulas_de_Matematica-1.png"),
          name: "500 Formulas de Matemática",
          text: "A ajuda que você precisa na hora de estudar Matemática.",
          nameButton: "Baixar e-book",
          urlDownload: "https://dicas.cpv.com.br/500-formulas-de-matematica",
          btn__default: "btn__matematica",
          text_color: "text__matematica",
        },
        {
          id: 7,
          altImg: "Tabela de Química",
          img: require("@/components/global/image/ebooks-vestibulares/newImageBook/Tabela_de_Quimica-1.png"),
          name: "Tabela de Química",
          text: "A ajuda que você precisa na hora de estudar Química.",
          nameButton: "Baixar e-book",
          urlDownload: "https://dicas.cpv.com.br/tabela-de-quimica",
          btn__default: "btn__quimica",
          text_color: "text__quimica",
        },
      ],
    };
  },
  created: function () {
    this.waitForEcho()
      .then(() => {
        this.joinChannel();
      })
      .catch((error) => {
        console.error("Erro ao conectar ao Echo:", error);
      });
  },
  beforeDestroy: function () {
    if (this.echoTimeout) {
      clearTimeout(this.echoTimeout);
    }
    window.Echo.leave(`cursinho.cpv.ebook`);
  },
  methods: {
    waitForEcho(timeout = 10000) {
      return new Promise((resolve, reject) => {
        const startTime = Date.now();
        const checkEcho = () => {
          if (window.Echo && window.Echo.connector && window.Echo.connector.pusher) {
            console.log("Echo encontrado");
            if (window.Echo.connector.pusher.connection.state === "connected") {
              console.log("Echo conectado");
              resolve();
              return;
            }
          }

          if (Date.now() - startTime >= timeout) {
            reject(new Error("Timeout ao tentar conectar ao Echo"));
            return;
          }

          this.echoTimeout = setTimeout(checkEcho, 1000); // Verifica novamente em 100ms
        };
        checkEcho();
      });
    },
    joinChannel() {
      console.info("Tentando entrar no canal E-Book");
      window.Echo.join(`cursinho.cpv.ebook`)
        .here((users) => {
          console.info("Entrou no canal E-Book");
        })
        .error((error) => {
          console.info("Erro ao entrar no canal E-Book");
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-in-up {
  -webkit-animation: fadeIn 0.4s ease-in-out;
  -moz-animation: fadeIn 0.4s ease-in-out;
  -o-animation: fadeIn 0.4s ease-in-out;
  animation: fadeIn 0.4s ease-in-out;
}

.title_page {
  margin: -8px 0px 55px !important;
  background-repeat: repeat;
  background-position: center;
  background-color: rgb(239, 72, 87);
  background-image: url("@/assets/image/cpv-fundo-4.png");
  background-size: 30%;
  /* background-attachment: fixed; */
  object-fit: cover;
  border-radius: 0;
}

.title_page h1 {
  font-size: 51px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 301.75px);
  grid-template-rows: auto;
  column-gap: 25px;
  row-gap: 40px;

  justify-content: center;
}

.box {
  height: 410px;
}

.box img {
  width: 301.75px;
  height: 286.66px;
  object-fit: cover;
  background-position: right;
  border-radius: 12px;
}

.title span {
  font-family: "Clan-Book Book", sans-serif;
  font-size: 18px;
}

.title .text__einstein {
  color: #653a91;
}
.title .text__fisica {
  color: #c7545b;
}
.title .text__matematica {
  color: #31114d;
}
.title .text__quimica {
  color: #432f6c;
}

.text span {
  font-family: "FF Clan Pro", sans-serif;
  font-size: 14px;
}

.btn__download {
  font-family: "FF Clan Pro", sans-serif;
  padding: 8px 16px;
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.25;
  font-size: 13px;
}

.btn__einstein {
  border: 2px solid #653a91;
  color: #653a91;
  text-decoration: none;

  &:hover {
    background-color: #653a91;
    color: #fff;
    transition: all 0.3s ease-in-out;
  }
}

.btn__fisica {
  border: 2px solid #c7545b;
  color: #c7545b;
  text-decoration: none;

  &:hover {
    border: 2px solid #c7545b;
    background: #c7545b;
    color: #fff;
    transition: all 0.3s ease-in-out;
  }
}

.btn__matematica {
  border: 2px solid #31114d;
  color: #31114d;
  text-decoration: none;

  &:hover {
    border: 2px solid #31114d;
    background: #31114d;
    color: #fff;
    transition: all 0.3s ease-in-out;
  }
}

.btn__quimica {
  border: 2px solid #432f6c;
  color: #432f6c;
  text-decoration: none;

  &:hover {
    border: 2px solid #432f6c;
    background: #432f6c;
    color: #fff;
    transition: all 0.3s ease-in-out;
  }
}

@media only screen and (min-width: 1128px) and (max-width: 1280px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 280px);
    grid-template-rows: auto;
    column-gap: 25px;
    row-gap: 50px;

    justify-content: center;
  }

  .box img {
    width: 100%;
    height: 261.48px;
    object-fit: cover;
    border-radius: 12px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1128px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 289.33px);
    grid-template-rows: auto;
    column-gap: 25px;
    row-gap: 50px;

    justify-content: center;
  }

  .box img {
    width: 100%;
    height: 274.86px;
    object-fit: cover;
    border-radius: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 352px);
    grid-template-rows: auto;
    column-gap: 25px;
    row-gap: 50px;

    justify-content: center;
  }

  .box img {
    width: 100%;
    height: 334.39px;
    object-fit: cover;
    border-radius: 12px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(2, 293px);
    grid-template-rows: auto;
    column-gap: 25px;
    row-gap: 50px;

    justify-content: center;
  }

  .box img {
    width: 293px;
    height: 278.34px;
    object-fit: cover;
    background-position: right;
    border-radius: 12px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(1, 400px);
    grid-template-rows: auto;
    column-gap: 25px;
    row-gap: 40px;

    justify-content: center;
  }

  .box {
    height: 480px;
  }

  .box img {
    width: 400px;
    height: 377.39px;
    object-fit: cover;
    background-position: right;
    border-radius: 12px;
  }
}
</style>
