import Cursinho from "@/views/Index.vue";
import Aprovados from "@/views/Aprovados";
import Bolsa from "@/views/exame_de_bolsa";
import Calendario from "@/views/calendario_vestibulares";
import Ebook from "@/views/Ebook";
import Moradia from "@/views/Moradia";
import moradias from "@/views/moradias";
import SimuladosAbertos from "@/views/simulados_abertos";
import CpvResolve from "@/views/CpvResolve";
// import ProximasTurmas from "@/views/proximas_turmas"
import Matricula from "@/views/proximasTurmas/matricula-cursinho.vue";
import ListasDeObras from "@/views/proximasTurmas/course/obrasExigidasFgv";
import ProvaMackenzie from "@/views/provas_Gabaritos/Mackenzie";
import ProvaPUCSP from "@/views/provas_Gabaritos/PucSP";
import ProvaPUCCampinas from "@/views/provas_Gabaritos/PucCampinas";
import ProvaFGVAdm from "@/views/provas_Gabaritos/FgvAdm";
import ProvaFGVDir from "@/views/provas_Gabaritos/FgvDireito";
import ProvaFGVEco from "@/views/provas_Gabaritos/FgvEconomia";
import ProvaEnem from "@/views/provas_Gabaritos/Enem";
import ProvaESPM from "@/views/provas_Gabaritos/ESPM";
import ProvaFuvest from "@/views/provas_Gabaritos/Fuvest";
import ProvaEinstein from "@/views/provas_Gabaritos/albert_einstein.vue";
import ProvaInsper from "@/views/provas_Gabaritos/Insper";
import ProvaUnesp from "@/views/provas_Gabaritos/Unesp";
import ProvaUnicamp from "@/views/provas_Gabaritos/Unicamp";
import ProvaUnifesp from "@/views/provas_Gabaritos/Unifesp";
import ProvaFMABC from "@/views/provas_Gabaritos/fmabc.vue";
import ProvaSantaCasa from "@/views/provas_Gabaritos/santaCasa.vue"
import Error404 from "@/views/error404";
import contato from "@/views/contatoCursinho";

import DetalheCurso from "@/views/proximasTurmas/cursoDetalhe";
import ProximasTurmas from "@/views/proximasTurmas/proximasTurmas";
import { name } from "vueisotope";

const routes = [
  {
    path: "/",
    name: "cursinho",
    component: Cursinho,
    meta: { section_name: "cursinho" },
  },

  {
    path: "/bolsa",
    name: "exame-bolsa",
    component: Bolsa,
    meta: { section_name: "cursinho", section_childname: 'exame-bolsa', title: "Exame de Bolsa" },
  },
  {
    path: "/calendario-de-vestibulares",
    name: "calendario-de-vestibulares",
    component: Calendario,
    meta: { section_name: "cursinho", section_childname: 'calendario-vestibulares', title: "Calendário de Vestibulares" },
  },
  {
    path: "/ebook",
    name: "ebook",
    component: Ebook,
    meta: {
      section_name: "cursinho", section_childname: 'ebook', title: "E-books" },
  },
  {
    path: "/simulados-abertos-cpv",
    name: "simulados-abertos-cpv",
    component: SimuladosAbertos,
    meta: { section_name: "cursinho", section_childname: 'simulados-abertos-cpv', title: "Simulados Abertos" },
  },
  {
    path: "/cpv-resolve",
    name: "cpv-resolve",
    component: CpvResolve,
    meta: { section_name: "cursinho", section_childname: 'cpv-resolve', title: "CPV Resolve" },
  },
  {
    path: "/proximasturmas",
    name: "proximasturmas",
    component: ProximasTurmas,
    meta: {
      section_name: "cursinho",
      section_childname: 'proximasturmas',
      title: 'Próximas Turmas'
    },
  },
  {
    path: "/proximasturmas/:slug",
    name: "detalhe-do-curso",
    component: DetalheCurso,
    props: true,
    meta: { section_name: "cursinho", section_childname: 'proximasturmas', section_thirdname: 'detalhe-do-curso', },
  },
  {
    path: "/proximasturmas/matricula/:tabelaPrecosId/:slug/",
    name: "matricula",
    component: Matricula,
    props: (route) => {
      return {
        ...route.params,
        ...{ tabelaPrecosId: Number.parseInt(route.params.tabelaPrecosId, 10), slug: route.params.slug },
      };
    },
    meta: { section_name: "cursinho", section_childname: 'proximasturmas', section_thirdname: 'matricula' },
  },
  // {
  //   path: "/proximasturmas/pagina_curso/:id",
  //   name: "pagina_curso",
  //   component: PaginasCursos,
  //   meta: { section_name: "cursinho" },
  // },
  {
    path: "/alfred",
    name: "alfred",
    beforeEnter: () => {
      location.href = "https://alfred.com.br/";
    },
  },
  {
    path: "/classapp",
    name: "classapp",
    beforeEnter: () => {
      location.href = "https://classapp.com.br/";
    },
  },
  {
    path: "/classroom",
    name: "classroom",
    beforeEnter: () => {
      location.href = "https://classroom.google.com/h";
    },
  },
  {
    path: "/carreiras",
    name: "carreiras",
    beforeEnter: () => {
      location.href = "https://cpv.test:8085/carreiras";
    },
  },
  {
    path: "/whatsapp",
    name: "whatsapp",
    beforeEnter: () => {
      location.href =
        "https://wa.me/551137402110?text=Ol%C3%A1!%20eu%20estou%20no%20site%20cpv.com.br%20e%20preciso%20de%20atendimento.";
    },
  },
  {
    path: "/colegio-cpv",
    name: "colegio",
    beforeEnter() {
      location.href = "https://colegio.cpv.com.br";
    },
  },
  {
    path: "/listas-de-obras",
    name: "listas-de-obras",
    component: ListasDeObras,
    meta: { section_name: "cursinho", title: "Lista de Obras" },
  },
  {
    path: "/contato",
    name: "contato",
    beforeEnter: () => {
      location.href = "https://cpv.com.br/contato";
    },
  },
  {
    path: "/grupo-cpv",
    name: "grupo-cpv",
    beforeEnter: () => {
      location.href = "https://cpv.test:8085/quem-somos";
    },
  },
  {
    path: "/vestibular-resolvido-mack",
    name: "vestibular-resolvido-mack",
    component: ProvaMackenzie,
    meta: {
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-mack',
      title: "Provas e Gabaritos - Mackenzie"
    },
  },
  {
    path: "/vestibular-resolvido-puc-sp",
    name: "vestibular-resolvido-puc-sp",
    component: ProvaPUCSP,
    meta: {
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-puc-sp',
      title: "Provas e Gabaritos - PUC SÃO PAULO"
    },
  },
  {
    path: "/vestibular-resolvido-puc-campinas",
    name: "vestibular-resolvido-puc-campinas",
    component: ProvaPUCCampinas,
    meta: {
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-puc-campinas',
      title: "Provas e Gabaritos - PUC Campinas"
    },
  },
  {
    path: "/vestibular-resolvido-fgv-adm",
    name: "vestibular-resolvido-fgv-adm",
    component: ProvaFGVAdm,
    meta: {
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-fgv-adm',
      title: "Provas e GABARITOS - FGV ADM"
    },
  },
  {
    path: "/vestibular-resolvido-fgv-direito",
    name: "vestibular-resolvido-fgv-direito",
    component: ProvaFGVDir,
    meta: {
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-fgv-direito',
      title: "Provas e GABARITOS - FGV DIREITO"
    },
  },
  {
    path: "/vestibular-resolvido-fgv-economia",
    name: "vestibular-resolvido-fgv-economia",
    component: ProvaFGVEco,
    meta: {
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-fgv-economia',
      title: "Provas e GABARITOS - FGV Economia"
    },
  },
  {
    path: "/vestibular-resolvido-enem",
    name: "vestibular-resolvido-enem",
    component: ProvaEnem,
    meta: {
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-enem',
      title: "Provas e GABARITOS - ENEM"
    },
  },
  {
    path: "/vestibular-resolvido-espm",
    name: "vestibular-resolvido-espm",
    component: ProvaESPM,
    meta: {
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-espm',
      title: "Provas e GABARITOS - ESPM"
    },
  },
  {
    path: "/vestibular-resolvido-fuvest",
    name: "vestibular-resolvido-fuvest",
    component: ProvaFuvest,
    meta: {
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-fuvest',
      title: "Provas e GABARITOS - FUVEST"
    },
  },
  {
    path: "/vestibular-resolvido-albert-einstein",
    name: "vestibular-resolvido",
    component: ProvaEinstein,
    meta: { 
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-albert-einstein',
      title: "Provas e GABARITOS - ALBERT EINSTEIN"
    },
  },
  {
    path: "/vestibular-resolvido-insper",
    name: "vestibular-resolvido-insper",
    component: ProvaInsper,
    meta: {
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-insper',
      title: "Provas e GABARITOS - Insper"
    },
  },
  {
    path: "/vestibular-resolvido-unesp",
    name: "vestibular-resolvido-unesp",
    component: ProvaUnesp,
    meta: {
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-unesp',
      title: "Provas e GABARITOS - FGV Unesp"
    },
  },
  {
    path: "/vestibular-resolvido-unicamp",
    name: "vestibular-resolvido-unicamp",
    component: ProvaUnicamp,
    meta: {
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-unicamp',
      title: "Provas e GABARITOS - Unicamp"
    },
  },
  {
    path: "/vestibular-resolvido-unifesp",
    name: "vestibular-resolvido-unifesp",
    component: ProvaUnifesp,
    meta: {
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-unifesp',
      title: "Provas e GABARITOS - Unifesp"
    },
  },
  {
    path: "/vestibular-resolvido-fmabc",
    name: "vestibular-resolvido-fmabc",
    component: ProvaFMABC,
    meta: {
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-unifesp',
      title: "Provas e GABARITOS - FMABC"
    },
  },
  {
    path: "/vestibular-resolvido-santa-casa",
    name: "vestibular-resolvido-santa-casa",
    component: ProvaSantaCasa,
    meta: {
      section_name: "cursinho",
      section_childname: 'cpv-resolve',
      section_cpvResolve: 'cpv-unifesp',
      title: "Provas e Gabaritos - Santa Casa"
    },
  },
  {
    path: "*",
    name: "Error404",
    component: Error404,
    meta: { section_name: "cursinho" },
  },
  {
    path: "/error404-*",
  },
];
export default routes;
