<template>
  <div class="main-error fade-in-up">
    <b-row>
      <b-col cols="12" sm="12" md="12">
        <img
          src="@/assets/image/CPV_educacional_positivo_sem_grafismo.webp"
          width="175"
          height="auto"
          class="fluid"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3 mb-0">
      <b-col cols="12" sm="12" md="12">
        <h1 class="text-uppercase">Error 404</h1>
      </b-col>
    </b-row>
    <b-row class="">
      <b-col cols="12" sm="12" md="12">
        <p class="">
          A página não foi encontrada ou o endreço digitado está errado!
        </p>
      </b-col>
    </b-row>
    <b-row class="">
      <b-col cols="12" sm="12" md="12">
        <b-button class="btn-error404" :to="{ name: 'cursinho' }">
          <span>Voltar</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Error404",
};
</script>

<style lang="scss" scoped>
.main-error {
  margin-top: 90px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  background-color: #f6f6f6;

  width: auto;
  height: 550px;

  img {
    width: 175px;
    height: auto;
  }

  h1 {
    font-weight: 800;
    color: rgb(259, 72, 87);
    font-size: 3.5em;
  }

  p {
    font-weight: 600;
    color: #333333;
  }

  .btn-error404 {
    display: inline-block;
    min-width: 135px;
    margin-bottom: 0;
    font-weight: 700;

    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    background-image: none;
    padding: 8px 16px;
    background: linear-gradient(
      90deg,
      rgb(239, 50, 66) 0%,
      rgb(239, 50, 66) 50%,
      rgb(239, 72, 87) 50%,
      rgb(239, 72, 87) 100%
    );
    background-size: 200% 100%;
    background-position: right;
    color: #fff;
    transition: background 0.5s ease;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 1.25px !important;
    text-align: center;
    outline: center;

    text-decoration: none;
    border-radius: 4px;
    font-size: 14px;
    border: none;
    
    &:hover {
      background-position: left;
    }
  }
}
</style>
