window.axios = require("axios");
import Vue from "vue";
import App from "./App.vue";
import _ from "lodash";
import ApiService from "./common/api.service";
import router from "./router";
import store from "./store";
import "./filters";
import VueGtm from "@gtm-support/vue2-gtm";

/* Importe do Botão do Whatsapp */
import WhatsappButton from "@/components/global/whatsapp-button";
Vue.component("WhatsappButton", WhatsappButton);

import VueCookies from "vue-cookies";
// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expire: "7d" });

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

//bootstrap vue

global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;

import {
  LayoutPlugin,
  ModalPlugin,
  CardPlugin,
  NavbarPlugin,
  ButtonPlugin,
  ImagePlugin,
  LinkPlugin,
  BreadcrumbPlugin,
  JumbotronPlugin,
  BadgePlugin,
  CarouselPlugin,
  ProgressPlugin,
  PopoverPlugin,
  EmbedPlugin,
} from "bootstrap-vue";

Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(CardPlugin);
Vue.use(NavbarPlugin);
Vue.use(ButtonPlugin);
Vue.use(ImagePlugin);
Vue.use(LinkPlugin);
Vue.use(BreadcrumbPlugin);
Vue.use(JumbotronPlugin);
Vue.use(BadgePlugin);
Vue.use(CarouselPlugin);
Vue.use(ProgressPlugin);
Vue.use(PopoverPlugin);
Vue.use(EmbedPlugin);

import "./assets/sass/cpv.scss";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("VSelect", vSelect);

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
const sweetoptions = {
  confirmButtonColor: "#4361ee",
  cancelButtonColor: "#e7515a",
};
Vue.use(VueSweetalert2, sweetoptions);

import Snotify, { SnotifyPosition } from "vue-snotify";
const options = {
  toast: {
    position: SnotifyPosition.rightTop,
    pauseOnHover: true,
  },
};
Vue.use(Snotify, options);
import "vue-snotify/styles/material.css";

//vue-meta
import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.use(_);
ApiService.init();

import EchoService from "./common/echo";
EchoService();

Vue.config.productionTip = false;
Vue.config.performance = false;
// Vue.config.devtools = true;
// Vue.config.debug = true;
// Vue.config.silent = false;

Vue.prototype.$trackEvent = function (eventType, eventData) {
  this.$gtm.push({
    event: eventType,
    ...eventData,
  });
};

Vue.use(VueGtm, {
  id: "GTM-MHT28T",
  enabled: true,
  debug: process.env.NODE_ENV !== "production",
  vueRouter: router,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
