<template>
  <div class="main-sidebar">
    <div class="single_page">
      <div class="product_main_data heading_font">
        <div class="cart">
          <b-link
            :to="{
              name: 'matricula',
              params: { slug: slug, tabelaPrecosId: curso.tabela_precos_id },
            }"
            name="add-to-cart"
            class="single_add_cart button_mat"
            >Matricule-se</b-link
          >
        </div>
      </div>
      <div class="product_sidebar_meta_units">
        <div class="product_sidebar_meta_unit">
          <table>
            <tbody>
              <tr>
                <td class="icon">
                  <img src="@/assets/image/icons-png/clock.png" width="35" alt="" />
                </td>
                <td class="value h5">
                  <p class="price mb-0 mt-2">
                    <label class="h6 title_price_label">Horário:</label>
                    {{ curso.horario }}
                  </p>
                  <!-- <p class="mt-0" v-show="more_info !== ''">
                    {{ more_info }}
                  </p> -->
                </td>
                <br />
              </tr>
            </tbody>
          </table>
        </div>
        <div class="product_sidebar_meta_unit">
          <table>
            <tbody>
              <tr>
                <td class="icon">
                  <img src="@/assets/image/icons-png/calendar.png" width="35" alt="" />
                </td>
                <td class="value h5">
                  <p class="price heading_font mt-2">
                    <label class="h6 title_price_label">Início:</label>
                    {{ curso.data_de_inicio | formatShortDate }}
                  </p>
                  <p class="price heading_font mt-3" v-if="curso.data_de_fim">
                    <label class="h6 title_price_label">Término:</label>
                    {{ curso.data_de_fim | formatShortDate }}
                  </p>
                  <p class="price heading_font mt-3" v-else-if="curso.data_de_fim_texto">
                    <label class="h6 title_price_label">Término:</label>
                    {{ curso.data_de_fim_texto }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="product_sidebar_meta_unit mb-0">
          <table>
            <tbody>
              <tr>
                <td class="icon">
                  <img src="@/assets/image/icons-png/cents.png" width="35" alt="" />
                </td>
                <td class="value h5">
                  <p class="price heading_font" v-show="curso.valor_total_presencial !== ''">
                    <label class="h6 title_price_label">Valor (Presencial):</label>

                    <span class="amount">
                      <span class="currencySymbol">R$</span> {{ curso.valor_total_presencial | formatDecimalNumber }}
                    </span>
                    <!-- <span class="amount" v-if="value_parce_pre !== ''">
                        <span class="currencySymbol">{{ value_parce_pre }}</span>
                      </span>
                      <br />
                      <span class="amount">
                        <span class="currencySymbol">{{ info_parce }}</span>
                      </span> -->
                  </p>
                  <p class="price heading_font mt-3" v-if="curso.valor_total_ead !== ''">
                    <label class="h6 title_price_label mt-0">Valor (EaD):</label>

                    <span class="amount">
                      <span class="currencySymbol">R$</span> {{ curso.valor_total_ead | formatDecimalNumber }}
                    </span>
                    <!-- <span class="amount" v-if="value_parce_ead !== ''">
                        <span class="currencySymbol">{{ value_parce_ead }}</span>
                      </span>
                      <br />
                      <span class="amount">
                        <span class="currencySymbol">{{ info_parce }}</span>
                      </span> -->
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="product_sidebar_meta_unit">
          <table>
            <tbody>
              <tr>
                <td class="icon">
                  <img src="@/assets/image/icons-png/school.png" width="35" alt="" />
                </td>
                <td class="value h5">
                  <p class="price font-weight-bold mt-2 mb-0">
                    <label class="h6 title_price_label">Unidade(s):</label>
                    {{ curso.unidades }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="product_main_data heading_font">
        <div class="cart">
          <b-link href="/contato" name="add-to-cart" class="single_add_contact button_cont">
            <i class="fa fa-phone pr-2 fa-lg"></i>
            <span>Contate nossas unidades </span>
          </b-link>
        </div>
      </div>
      <!-- <div class="product_main_data heading_font mt-4">
        <div class="cart">
          <a href="#" name="img_aprovados_cpv">
            <img
              src="https://media.cpv.com.br/wp-content/uploads/sites/8/2016/11/16122544/confira_aprovados_todos.png"
              alt="Confira os aprovados CPV"
              class="img-fluid"
              width="350"
            />
          </a>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["curso", "slug"],
};
</script>

<style scoped>
.main-sidebar {
  font-family: "FF Clan Pro", "Clan-Book Book", sans-serif;
  margin-left: 30px;
}

.single_page {
  position: relative;
  padding-bottom: 45px;

  margin-top: 20px;
  z-index: 11;
  background-color: #fff;

  width: 100%;
}

.single_page .price {
  margin-bottom: 20px;
}

.single_page .price label {
  font-weight: 400;
  color: #727272;
}

.single_page .price ins {
  text-decoration: none;
}

.single_page .price ins .amount {
  font-size: 15px;
  font-weight: 700;
}

.single_page .price .title_price_label {
  display: block;
  margin: 0 0 8px;
  text-transform: uppercase;
}

.single_page .price .title_price_label {
  line-height: 13px;
  font-size: 13px;
  text-align: start;
}

.single_page .product_main_data .cart .button_mat {
  position: relative;
  overflow: hidden;
  -webkit-transorm: translateY(0);
  z-index: 30;
}

.single_page .product_main_data .cart .button_mat {
  display: inline-block;
  min-width: 135px;
  margin-bottom: 0;
  font-weight: 700;

  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  background-image: none;
  padding: 25px 15px;
  background: linear-gradient(
    90deg,
    rgb(239, 50, 66) 0%,
    rgb(239, 50, 66) 50%,
    rgb(239, 72, 87) 50%,
    rgb(239, 72, 87) 100%
  );
  background-size: 200% 100%;
  background-position: right;
  color: #fff;
  transition: background 0.5s ease;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 1.5px !important;
  text-align: center;
  outline: center;

  text-decoration: none;
  font-family: "FF Clan Pro", sans-serif;
}

.single_page .product_main_data .cart .button_mat:hover {
  background-position: left;
}

.single_page .product_main_data .cart .single_add_contact {
  width: 100%;
  max-width: 350px;
  text-align: center;
  height: 56px;
  font-size: 12px;
  letter-spacing: -0.2px;
}

.single_page .product_main_data .cart .button_cont {
  position: relative;
  overflow: hidden;
  -webkit-transorm: translateY(0);
  z-index: 30;
}

.single_page .product_main_data .cart .button_cont {
  display: inline-block;
  min-width: 135px;
  margin-bottom: 0;
  font-weight: 700;

  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  background-image: none;
  padding: 19px 15px;
  background: linear-gradient(
    90deg,
    rgb(239, 50, 66) 0%,
    rgb(239, 50, 66) 50%,
    rgb(239, 72, 87) 50%,
    rgb(239, 72, 87) 100%
  );
  background-size: 200% 100%;
  background-position: right;
  color: #fff;
  transition: background 0.5s ease;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  outline: center;

  text-decoration: none;
  font-family: "FF Clan Pro", sans-serif;
}

.single_page .product_main_data .cart .button_cont:hover {
  background-position: left;
}

.product_sidebar_meta_units {
  margin-top: 15px;
  font-family: "Clan-Book Book", sans-serif;
}

.product_sidebar_meta_units .product_sidebar_meta_unit table tbody td.icon {
  padding-left: 5px;
  width: 30px;
  font-size: 30px;
  vertical-align: middle;
  color: #aaaaaa;
}

.product_sidebar_meta_units .product_sidebar_meta_unit table tbody td.value p {
  margin: 0;
  color: #555555;
  line-height: 20px;
  font-weight: 700;
  font-size: 15px;
  padding: 0px 10px 0px 20px;
  width: 100%;
}

.product_sidebar_meta_units .product_sidebar_meta_unit table tbody td {
  border-bottom: 1px solid #e1e2e4;
  padding: 23px 0px;
  width: 100%;
}

.single_page .product_main_data .cart .single_add_cart {
  width: 400px;
  max-width: 320px;
  text-align: center;
  height: 75px;
  font-size: 18px;
  letter-spacing: -0.2px;
}
</style>
