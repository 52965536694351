<template>
  <div class="course_detalhe">
    <div v-if="isLoading" style="height: 60vh" class="d-flex align-items-center">
      <loading message="Carregando Curso"></loading>
    </div>
    <div v-else class="main_course fade-in-up mb-5">
      <jumbotron-course :title="curso.nome_site" class="jumbotron_course" />
      <bread-cpv :title="curso.nome_site" :slug="slug" />
      <div class="content_course">
        <b-container fluid="lg">
          <b-row>
            <b-col cols="12" md="12" lg="8" class="column_content-course">
              <div class="sidebar_position_right">
                <!-- <info-header-course :title="curso.nome_site" :classes="curso.unidades" :date="curso.data_de_inicio" />
                <line-divisory /> -->
                <component class="mt-2" v-if="cursoComponent" :is="cursoComponent" />
                <!-- <curso-component></curso-component> -->
              </div>
            </b-col>
            <b-col cols="12" md="4" class="d-none d-md-none d-lg-block">
              <sidebarMatricula class="right" :curso="curso" :slug="slug" />
            </b-col>
          </b-row>
        </b-container>
      </div>
      <panel-matricula
        :titulo="curso.nome_site"
        icone="fa-regular fa-clock-seven"
        :hora="curso.short_horario"
        :unidades="curso.unidades"
        cifrao="R$"
        :valorPresencial="curso.valor_total_presencial"
        :valorOnline="curso.valor_total_ead"
        iconMaps="fa-sharp fa-regular fa-location-dot"
        :slug="slug"
        :tabela_precos_id="curso.tabela_precos_id"
      />
      <WhatsButton :isPanelVisible="isPanelVisible" />
    </div>
  </div>
</template>

<script>
import WhatsButton from "@/components/global/whatsapp-button.vue";
import JumbotronCourse from "@/components/global/components_cursos/jumbotron_courses";
import BreadCpv from "@/components/global/bread-cpv.vue";
import InfoHeaderCourse from "@/components/global/components_cursos/more_info.vue";
import LineDivisory from "@/components/global/components_cursos/divisory";
import PanelMatricula from "./panel";
import SidebarMatricula from "./sidebar_matricula";

/* PAGES .VUE EXTENSIVO */
// import extensivo from "./course/extensivo/extensivo.vue";
// import extensivoMedicina from "./course/extensivo/extensivo-medicina-exatas.vue";
// import extensivoFgvDireito from "./course/extensivo/extensivo-fgv-direito.vue";
// import extensivoFgvEconomia from "./course/extensivo/extensivoEconomia.vue";

/* PAGES .VUE 1º SEMESTRE */
// import semiFgvInsper from "./course/primeiroSemestre/semiFgvInsper.vue";
// import Semi from "./course/primeiroSemestre/semi.vue";
// import IntensivoFgvInsper from "./course/primeiroSemestre/intensivoFgvInsper.vue";
// import PreparacaoParaJornadaLink from "./course/primeiroSemestre/preparacaoJornadaLink.vue";
// import IntensivoEspm from "./course/primeiroSemestre/intensivoEspm.vue";
// import RevisaoFgvInsper from "./course/primeiroSemestre/revisaoFgvInsper.vue";

/* PAGES .VUE 2º SEMESTRE */
// import SemiFgvInsper from "./course/segundoSemestre/semiFgvInsperManha.vue";
// import SemiFgvInsperTarde from "./course/segundoSemestre/semiFgvInsperTarde.vue";
// import SemiSegundoSemestre from "./course/segundoSemestre/semi.vue";
// import EspecialFgvDireito from "./course/segundoSemestre/especialFgvDireito.vue";
// import IntensivoMedicinaEinstein from "./course/segundoSemestre/intensivoMedicinaEinstein.vue";
// import IntensivoFGVInsperTarde from "./course/segundoSemestre/intensivoFgvInsper.vue";
// import IntensivoEspmTarde from "./course/segundoSemestre/intensivoEspm.vue";
// import IntensivoFuvest from "./course/segundoSemestre/intensivoFuvest.vue";
// import PreparacaoJornadaLink from "./course/segundoSemestre/preparacaoJornadaLink.vue";
// import RevisaoFgvTarde from "./course/segundoSemestre/revisaoFgvTarde.vue";
// import RevisaoFuvestUm from "./course/segundoSemestre/revisaoFuvestUm.vue";
// import RevisaoFuvestDois from "./course/segundoSemestre/revisaoFuvestDois.vue";
// import RevisaoFuvestTarde from "./course/segundoSemestre/revisaoFuvestTarde.vue";
// import RevisaoMedicinaFMABC from "./course/segundoSemestre/revisaoMedicinaFmabc.vue";
// import RevisaoInsper from "./course/segundoSemestre/revisaoInsper.vue";
// import PreparacaoEinsteinMme from "./course/segundoSemestre/preparacaoEinsteinMme.vue";

// import cursoComponent from "./course/segundoSemestre/semiFgvInsperManha.vue";
import ApiService from "@/common/api.service";
import Loading from "@/components/loading.vue";

export default {
  name: "CursoDetalhe",
  props: {
    slug: {
      type: String,
      default: null,
    },
  },
  components: {
    Loading,
    JumbotronCourse,
    // InfoHeaderCourse,
    LineDivisory,
    PanelMatricula,
    SidebarMatricula,
    BreadCpv,
    WhatsButton,
    // cursoComponent,
  },
  data() {
    return {
      isLoading: false,
      curso: null,
      cursoComponent: null,
      isPanelVisible: false,
      echoTimeout: null,
    };
  },
  computed: {
    matriculaLink() {
      return {
        name: "MatriculeSe",
        params: {
          turma: this.curso.turma,
          slug: this.curso.slug,
        },
      };
    },
  },
  created() {
    this.getCourse();
    this.waitForEcho()
      .then(() => {
        this.joinChannel();
      })
      .catch((error) => {
        console.error("Erro ao conectar ao Echo:", error);
      });
  },
  beforeDestroy: function () {
    if (this.echoTimeout) {
      clearTimeout(this.echoTimeout);
    }
    window.Echo.leave(`cursinho.cpv.${this.slug}`);
  },
  methods: {
    getCourse: async function () {
      this.isLoading = true;
      try {
        await ApiService.get(`sitecpv/cursinho/proximasturmas/getCourse/${this.slug}`).then((response) => {
          this.curso = response.data.course;
        });
        if (this.curso.vue_component) {
          const componentPath = this.curso.vue_component;
          const module = await this.loadComponent(componentPath);
          this.cursoComponent = module.default;
        }

        this.isLoading = false;
      } catch (error) {
        console.error("Erro ao carregar o componente:", error);
      }
    },
    loadComponent: async function (componentPath) {
      try {
        const module = await import(`${componentPath}`);
        return module;
      } catch (error) {
        console.error(`Erro ao carregar o componente: ${componentPath}`, error);
      }
    },
    waitForEcho(timeout = 10000) {
      return new Promise((resolve, reject) => {
        const startTime = Date.now();
        const checkEcho = () => {
          if (window.Echo && window.Echo.connector && window.Echo.connector.pusher) {
            console.log("Echo encontrado");
            if (window.Echo.connector.pusher.connection.state === "connected") {
              console.log("Echo conectado");
              resolve();
              return;
            }
          }

          if (Date.now() - startTime >= timeout) {
            reject(new Error("Timeout ao tentar conectar ao Echo"));
            return;
          }

          this.echoTimeout = setTimeout(checkEcho, 1000); // Verifica novamente em 100ms
        };
        checkEcho();
      });
    },
    joinChannel() {
      console.info("Tentando entrar no canal Cursinho Matrícula");
      window.Echo.join(`cursinho.cpv.${this.slug}`)
        .here((users) => {
          console.info(`Entrou no canal Cursinho ${this.slug}`);
        })
        .error((error) => {
          console.info(`Erro ao entrar no canal Cursinho ${this.slug}`);
          console.error(error);
        });
    },
  },
};
</script>

<style lang="css" scoped>
.course_detalhe {
  font-family: "Montserrat", "Open Sans", sans-serif;
  font-size: 16px !important;
}
.jumbotron_course {
  border-radius: 0;
  background-color: rgb(259, 72, 87);
  background-image: url("");
}
</style>
