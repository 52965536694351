<template>
  <div class="mb-5 fade-in-up">
    <jumbotron-course :title="course[0].title"></jumbotron-course>
    <bread-cpv class="adjustment-breadcrumb" :title="curso.title" />
    <b-container class="mt-5 ">
      <section class="">
        <table class="table table-lg">
          <thead>
            <tr>
              <th class="text-center">Lista de Obras:</th>
            </tr>
          </thead>
          <tbody>
            <tr class="title_table">
              <td>Questões Contemporâneas:</td>
            </tr>
            <tr>
              <td>As origens do totalitarismo, de Hanna Arendt</td>
            </tr>
            <tr>
              <td>Ruptura: a crise da democracia liberal, Manuel Castells</td>
            </tr>
            <tr class="title_table">
              <td>Literatura:</td>
            </tr>
            <tr>
              <td>Torto Arado, de Itamar Vieira Junior</td>
            </tr>
            <tr>
              <td>Memórias póstumas de Brás Cubas, de Machado de Assis</td>
            </tr>
            <tr>
              <td>As meninas, de Lygia Fagundes Telles</td>
            </tr>
            <tr>
              <td>A hora da estrela, de Clarice Lispector</td>
            </tr>
            <tr>
              <td>Vidas secas, de Graciliano Ramos</td>
            </tr>
            <tr>
              <td>O bem-amado, de Dias Gomes</td>
            </tr>
            <tr>
              <td>Manifesto Antropófago, de Oswald de Andrade</td>
            </tr>
            <tr>
              <td>Barroco tropical, de José Eduardo Agualusa</td>
            </tr>
            <tr>
              <td>Arte, de Yasmina Reza</td>
            </tr>
            <tr>
              <td>A vergonha, de Annie Ernaux</td>
            </tr>
            <tr>
              <td>A Vida de Galileu, de Bertolt Brecht</td>
            </tr>
            <tr>
              <td>1984, de George Orwell</td>
            </tr>
            <tr>
              <td>Dicas da imensidão, de Margaret Atwood</td>
            </tr>
            <tr>
              <td>O olho mais azul, de Toni Morrison</td>
            </tr>
            <tr class="title_table">
              <td>Artes Visuais:</td>
            </tr>
            <tr>
              <td>Abaporu, de Tarsila do Amaral</td>
            </tr>
            <tr>
              <td>La Rentrée, de Anita Malfatti</td>
            </tr>
            <tr>
              <td>Bananal de Lasar Segall</td>
            </tr>
            <tr>
              <td>Bicho (1960) de Lygia Clark, coleção família Clark</td>
            </tr>
            <tr>
              <td>Mao Tse Tung de Andy Warhol</td>
            </tr>
            <tr>
              <td>Jogadores de Cartas de Paul Cézanne</td>
            </tr>
            <tr>
              <td>Guernica de Pablo Picasso</td>
            </tr>
            <tr>
              <td>Don’t forget to eat your lunch and make some trouble e
                Guerra e Spray de Bansky</td>
            </tr>
            <tr>
              <td>A morte de Marat de Jacques-Louis David</td>
            </tr>
            <tr class="title_table">
              <td>Cinema: </td>
            </tr>
            <tr>
              <td>O Pagador de promessas – Anselmo Duarte</td>
            </tr>
            <tr>
              <td>O Auto da Compadecida – Guel Arraes</td>
            </tr>
            <tr>
              <td>Cabra marcado para morrer – Eduardo Coutinho</td>
            </tr>
            <tr>
              <td>Que horas ela volta? – Anna Muylaert</td>
            </tr>
            <tr>
              <td>Laranja Mecânica – Stanley Kubrick</td>
            </tr>
            <tr>
              <td>Babel – Alejandro González Iñárritu</td>
            </tr>
            <tr>
              <td>The Post, A Guerra Secreta – Steven Spielberg</td>
            </tr>
            <tr>
              <td>Persépolis – Marjane Satrapi e Vincent Paronnaud</td>
            </tr>
            <tr>
              <td>Lady Bir: a hora de voar – Greta Gerwig</td>
            </tr>
            <tr class="title_table">
              <td>Música: </td>
            </tr>
            <tr>
              <td>Com que roupa – Noel Rosa</td>
            </tr>
            <tr>
              <td>Cálice – Chico Buarque</td>
            </tr>
            <tr>
              <td>Cálice – Criolo</td>
            </tr>
            <tr>
              <td>Malandragem – Cássia Eller</td>
            </tr>
            <tr>
              <td>Que País é Esse? – Legião Urbana</td>
            </tr>
            <tr>
              <td>Domingo no Parque – Gilberto Gil</td>
            </tr>
            <tr>
              <td>O mundo é um moinho - Cartola</td>
            </tr>
            <tr>
              <td>Rehab - Amy Winehouse</td>
            </tr>
            <tr>
              <td>Revolution - Beatles</td>
            </tr>
            <tr>
              <td>The boy in the bubble - Paul Simon</td>
            </tr>
            <tr>
              <td>Young Americans - David Bowie</td>
            </tr>
          </tbody>
        </table>
      </section>
    </b-container>
  </div>
</template>

<script>
import JumbotronCourse from '@/components/global/components_cursos/jumbotron_courses.vue';

export default {
  name: 'lista-de-obras',
  metaInfo: { title: '45 Obras Exigidas pela FGV' },
  components: {
    JumbotronCourse,
  },
  data() {
    return {
      course: [
        {
          title: '45 Obras exigidas pela FGV',
        },
      ],
    };
  },
};
</script>

<style scoped>
.table-lg thead tr {
  border: 1px solid rgb(239,72,87);
  background-color: rgb(239,72,87);
}

.table-lg thead tr th {
  border: 0px;
  padding: 8px 5px;
  color: #ffffff;
  font-size: 17px;

}

.table-lg tbody tr {
  border: 1px solid #e9e9e9;
}

.table-lg tbody .title_table:nth-child(n + 1) {
  background-color: #f6f6f6;
  font-weight: 700;
  color: #404040;
  font-size: 16px;
}

.table-lg tbody tr td {
  border-bottom: 1px solid #e9e9e9;
  border-top: 0px;
  font-size: 14px;
  color: #707070;
}

.table-lg tfoot tr {
  background-color: rgb(239,72,87);
  border: 1px solid rgb(239,72,87);
}

.table-lg tfoot tr td {
  color: #ffffff;
  font-weight: 700;
  padding: 8px 0px;
  font-size: 13px;
}

</style>
