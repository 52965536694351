<template>
  <div class="panel-menu" fixed="bottom" :class="{ is_visible: isVisible }">
    <div class="d-desktops mt-2">
      <!-- TÍTULO DO CURSO NO PANEL -->
      <div class="clearfix hidde__default">
        <div class="float-left">
          <h4 class="font-weight-bolder">{{ titulo }}</h4>
        </div>
      </div>

      <div class="clearfix d-none d-md-block d-lg-block ">
        <!-- INFO HORÁRIO E UNIDADES -->
        <div class="float-left hidde__default">
          <div class="info__hours__unitys mt-2">
            <div class="clearfix">
              <div class="float-left mr-3 border-right pt-2 pb-2 pr-3">
                <i :class="icone" class="mr-2"></i>
                <span class="font-weight-bolder">{{ hora }}</span>
              </div>
              <div class="float-right mr-3 border-right pt-2 pb-2 pr-3">
                <i :class="iconMaps" class="mr-2"></i>
                <span class="font-weight-bolder">{{ unidades }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- INFO VALOR PRESENCIAL & EAD E BOTÃO MATRICULA -->
        <div class="float-right">
          <div class="clearfix">
            <div class="float-left">
              <div class="info__values d-inline-flex">
                <div v-if="valorPresencial !== 0 || valorPresencial === !valorPresencial">
                  <div class="d-flex justify-content-end">
                    <h6 class="text-uppercase font-weight-lighter text-black-50">Presencial</h6>
                  </div>
                  <span class="font-weight-bolder">{{ cifrao }} {{  valorPresencial | formatDecimalNumber  }}</span>
                </div>
                <div class="ml-3" v-if="valorOnline !== 0 || valorOnline === !valorOnline">
                  <div>
                    <div class="d-flex justify-content-end">
                      <h6 class="text-uppercase font-weight-lighter text-black-50">EaD</h6>
                    </div>
                  </div>
                  <span class="font-weight-bolder">{{ cifrao }} {{  valorOnline | formatDecimalNumber  }}</span>
                </div>
              </div>
            </div>
            <!-- Botão Matricule-se -->
            <div class="float-right ml-2 mr-2">
              <b-link class="btn btn__default d-none d-block" :to="{ name: 'matricula', params: { slug: slug, tabelaPrecosId: tabela_precos_id }}">Matricule-se</b-link>
            </div>
          </div>
        </div>
      </div>

      <!-- For Mobile Display -->
      <div class="d-mobile pl-3 pr-3">
          <div class="clearfix">
            <div class="float-left d-inline-flex">
              <div v-if="valorPresencial !== 0 || valorPresencial === !valorPresencial">
                <div>
                  <div class="d-flex justify-content-end">
                    <h6 class="text-uppercase font-weight-lighter text-black-50">Presencial</h6>
                  </div>
                </div>
                <span class="font-weight-bolder">{{ cifrao }} {{ valorPresencial | formatDecimalNumber }}</span>
              </div>
              <div class="ml-3" v-if="valorOnline !== 0 || valorOnline === !valorOnline">
                <div>
                  <div class="d-flex justify-content-end">
                    <h6 class="text-uppercase font-weight-lighter text-black-50">EaD</h6>
                  </div>
                </div>
                <span class="font-weight-bolder">{{ cifrao }} {{ valorOnline | formatDecimalNumber }}</span>
              </div>
            </div>
          </div>
          <b-link class="btn btn__default d-block d-md-none" :to="{ name: 'matricula', params: { slug: slug, tabelaPrecosId: tabela_precos_id }}">Matricule-se</b-link>
        </div>
      </div>
      
    </div>
</template>

<script>
export default {
  props: [
    "titulo",
    "hora",
    "horaComp",
    "icone",
    "unidades",
    "cifrao",
    "valorPresencial",
    "valorOnline",
    "iconMaps",
    "segundoSemestre",
    "parcela",
    "slug",
    "tabela_precos_id"
  ],
  data() {
    return {
      isVisible: false,
      cursos: {},
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  },
  methods: {
    handleScroll() {
      if(window.scrollY >= 350) {
        this.isVisible = true
      } else {
        this.isVisible = false
      }
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Clan-Book Book", sans-serif;
}

span {
  font-family: "FF Clan Pro", sans-serif;
}

.d-desktops {
  width: 82%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.panel-menu {
  font-family: "Clan-Book Book", "FF Clan Pro" sans-serif;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 0px;
  background-color: #ffffff;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.29);
  z-index: 9999;
  -webkit-transform: translateY(0px);
  transform: translateY(20px);
  opacity: 0;
  visibility: hidden;
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  height: auto;
}

.panel-menu.is_visible {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
  visibility: visible;
}

.info__hours__unitys i {
  color: #ef4857;
}

.info__hours__unitys span {
  font-size: 14px;
}

.info__values h6 {
  font-size: 12px;
}

.info__values span {
  font-size: 26px;
}

/* .btn__default {
  /* -webkit-transform: translateY(0);
} */

.btn__default {
  display: inline-block;
  min-width: 135px;
  margin-bottom: 0;
  font-weight: bold;

  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  background-image: none;
  padding: 15px 25px;
  background: linear-gradient(
    90deg,
    rgb(239, 50, 66) 0%,
    rgb(239, 50, 66) 50%,
    rgb(239, 72, 87) 50%,
    rgb(239, 72, 87) 100%
  );
  background-size: 200% 100%;
  background-position: right;
  color: #fff;
  transition: background 0.5s ease;
  border-radius: 0;
  text-transform: uppercase;
  line-height: 1.5;
  text-align: center;
  outline: center;

  text-decoration: none;
  font-family: "FF Clan Pro", sans-serif;
}

.btn__default:hover {
  background-position: left;
  color: #fff;
}

.d-mobile {
  display: none;
}

@media only screen
  and (min-width: 992px)
  and (max-width: 1200px) {
    /* .hidde__default {
      display: none;
    } */

    .d-desktops {
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .info__values span {
      font-size: 25px;
    }

    .btn__default {
      width: 100%;
    }

    /* .panel-menu {
      font-family: "Clan-Book Book", "FF Clan Pro" sans-serif;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 0px;
      background-color: #ffffff;
      box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.29);
      z-index: 9999;
      -webkit-transform: translateY(0px);
      transform: translateY(20px);
      opacity: 0;
      visibility: hidden;
      transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
      height: 90px;
    } */

    /* .panel-menu.is_visible {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      opacity: 1;
      visibility: visible;
    } */
  }

@media only screen
  and (min-width: 768px)
  and (max-width: 992px) {
    .hidde__default {
      display: none;
    }

    .d-desktops {
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .info__values span {
      font-size: 25px;
    }

    .btn__default {
      width: 100%;
    }

    .panel-menu {
      font-family: "Clan-Book Book", "FF Clan Pro" sans-serif;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 0px;
      background-color: #ffffff;
      box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.29);
      z-index: 9999;
      -webkit-transform: translateY(0px);
      transform: translateY(20px);
      opacity: 0;
      visibility: hidden;
      transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
      height: 90px;
    }
  }

@media only screen
  and (min-width: 576px)
  and (max-width: 768px) {
    .hidde__default {
      display: none;
    }

    .panel-menu {
      height: 90px;
    }

    /* .d-mobile {
      display: block;
    } */

    .d-mobile h6 {
      font-size: 12px;
    }

    .d-mobile span {
      font-size: 22px;
    }

    .btn__default {
      display: flex;
      align-items: center;
      width: 100%;
      height: 47.40px;
      font-size: 16px;
    }

    .d-desktops {
      width: 100%;
      display: block;
    }
  }

@media only screen
  and (min-width: 320px)
  and (max-width: 576px) {
    .hidde__default {
      display: none;
    }

    .panel-menu {
      height: 135px;
    }

    .d-mobile {
      display: block;
    }

    .d-mobile h6 {
      font-size: 12px;
    }

    .d-mobile span {
      font-size: 22px;
    }

    .btn__default {
      display: flex;
      align-items: center;
      width: 100%;
      height: 47.40px;
      font-size: 16px;
    }

    .d-desktops {
      width: 100%;
      display: block;
    }
  }
</style>