var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-breadcrumb',{staticClass:"bg-light breadcrumb__cpv"},[_c('b-breadcrumb-item',{staticClass:"link__breadcrumb",attrs:{"to":{ name: 'cursinho' }}},[_c('span',{staticClass:"font-weight-bolder text-uppercase"},[_vm._v(_vm._s(_vm.mainSite))])]),(
      this.$route.meta.section_childname == 'proximasturmas' && this.$route.name !== 'proximasturmas' ||
      this.$route.meta.section_childname == 'cpv-resolve' && this.$route.name !== 'cpv-resolve'
      )?_c('b-breadcrumb-item',{staticClass:"link__breadcrumb",attrs:{"to":{ name: 
      this.$route.meta.section_childname == 'proximasturmas' 
      ? 'proximasturmas' 
      : this.$route.meta.section_childname == 'cpv-resolve' 
      ? 'cpv-resolve'
      : ''
    }}},[_c('span',{staticClass:"font-weight-bolder text-uppercase"},[_vm._v(_vm._s(_vm.singlePage))])]):_vm._e(),(this.$route.meta.section_thirdname == 'matricula')?_c('b-breadcrumb-item',{staticClass:"link__breadcrumb font-weight-bolder text-uppercase",attrs:{"to":{ name: 'detalhe-do-curso', params: { slug: this.$route.params.slug }},"active":this.$route.meta.section_thirdname == 'detalhe-do-curso'}},[_c('strong',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.pageCourse))])]):_vm._e(),(this.$route.meta.section_thirdname == 'matricula')?_c('b-breadcrumb-item',{attrs:{"active":true}},[_c('strong',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.pageMatriculaCourse))])]):_vm._e(),(this.$route.name !== 'matricula')?_c('b-breadcrumb-item',{staticClass:"link__breadcrumb text-uppercase",attrs:{"active":"","aria-current":"page"}},[_vm._v(_vm._s(_vm.$route.meta.title || _vm.title)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }