import Echo from "laravel-echo";
import Pusher from "pusher-js";
import ApiService from "./api.service";

// https://github.com/fingerprintjs/fingerprintjs/blob/master/docs/api.md#webpackrollupnpmyarn
import FingerprintJS from "@fingerprintjs/fingerprintjs";
// Initialize an agent at application startup.

window.Pusher = Pusher;

const EchoService = (retryCount = 0) => {
  if (!$cookies.isKey("visitor")) {
    $cookies.set("visitor", { id: null, token: null });
  }

  const fpPromise = FingerprintJS.load();
  // Get the visitor identifier when you need it.
  fpPromise
    .then((fp) => fp.get())
    .then((result) => {
      if ($cookies.get("visitor").id != result.visitorId) {
        $cookies.set("visitor", { id: result.visitorId, token: null });
      }
    })
    .then(async () => {
      console.log("visitorId: " + $cookies.get("visitor").id);

      try {
        if (retryCount > 5) {
          console.error("Max retry attempts reached.");
          return;
        }

        console.log(`Attempting to join channel... (retry count: ${retryCount})`);

        var serverURL;
        var key = null;

        var serverURL = process.env.VUE_APP_SERVER + "api/visitor-broadcasting/auth";
        var key = process.env.VUE_APP_PUSHER_KEY;

        let authToken = null;

        await ApiService.post(`guest-token`, {
          device_id: $cookies.get("visitor").id,
        }).then((response) => {
          if (response.status === 200) {
            if (response.data.token) {
              console.log("Token Ok");
              authToken = response.data.token;
              $cookies.set("visitor", { visitorId: $cookies.get("visitor").id, token: authToken });
            } else {
              authToken = null;
            }
          } else {
            authToken = null;
            console.log(response);
          }
        });
        if (authToken) {
          window.Echo = new Echo({
            broadcaster: "pusher",
            key: key,
            cluster: "mt1",
            forceTLS: true,
            authEndpoint: serverURL,
            auth: {
              headers: {
                Authorization: "Bearer " + authToken,
                "User-Type": "visitor",
              },
            },
          });

          // Capturando erros de autenticação e conexão
          window.Echo.connector.pusher.connection.bind("error", function (error) {
            if (error.error) {
              if (error.error.data) {
                console.log("Pusher error:", error.error.data);
              } else {
                console.log("Pusher error:", error);
              }
            } else {
              console.log("Pusher error:", error);
            }
          });

          // Capturando erros de autenticação específicos do Laravel Echo
          window.Echo.connector.pusher.connection.bind("pusher:subscription_error", function (status) {
            console.log("Subscription error:", status);
          });

          // Capturando desconexões
          window.Echo.connector.pusher.connection.bind("disconnected", function () {
            console.log("Pusher disconnected");
          });

          // Capturando reconexões
          window.Echo.connector.pusher.connection.bind("connected", function () {
            console.log("Pusher connected");
          });

          // Capturando tentativa de reconexão
          window.Echo.connector.pusher.connection.bind("connecting_in", function (delay) {
            console.log("Pusher attempting to reconnect in", delay, "seconds");
          });
        }
      } catch (error) {
        console.log(error);
      }
    });
};

export default EchoService;
